<template>
  <div>
    <div class="my-50" v-if="checkRequiredPermissions([$roles.FULL_ACCESS]) || checkRequiredViewPermissions([$viewRanks.SHOW_MARKETING_POINTS_EXPORT])">
      <b-button
        variant="primary"
        size="sm"
        :disabled="exportLoading"
        @click="getRecordsToExport"
      >
        <span v-if="!exportLoading"><feather-icon icon="DownloadIcon" />Export</span>
        <span v-else>
          <b-spinner
            small
            variant="light"
          />
        </span>
      </b-button>
    </div>
    <!-- eslint-disable -->
    <b-card>
        <sw-table
                table-id="3fd44a8b916849b7a463cec648bb7087"
                show-columns
                show-search
                :fields.async="tableFields"
                :pagination="pagination"
                @change-pagination="Object.assign(pagination, $event)"
                @change-search-query="search = $event"
                @reload-content="loadData"
                @set-fields="tableFields = $event"
        >
            <template #table>
                <!--      Table      -->
                <b-table
                        :items="points"
                        :fields="fields"
                        striped
                        responsive
                        :busy="loading"
                        show-empty
                        :sort-by.sync="sorter.sortBy"
                        :sort-desc.sync="sorter.sortDesc"
                        :no-local-sorting="true"
                        @sort-changed="Object.assign(sorter, $event); loadData()"
                >
                    <!--    Head    -->
                    <template #head()="{ label }">
                        {{ $t(`${label}`) }}
                    </template>
                    <template #cell(id)="{ index }">
                        #{{ getOrderNo(index, pagination, sorter) }}
                    </template>

                    <template #cell(user)="{ item }">
                        <avatar class="py-25" :user="item.user" v-if="item.user" />
                        <feather-icon
                                v-else
                                icon="MinusIcon"
                        />
                    </template>

                    <template #cell(seller)="{ item }">
                        <avatar class="py-25" :user="item.contactThreadCartBox.contactThreadCartOrder.seller" v-if="item.contactThreadCartBox.contactThreadCartOrder && item.contactThreadCartBox.contactThreadCartOrder.seller" />
                        <feather-icon
                                v-else
                                icon="MinusIcon"
                        />
                    </template>

                    <template #cell(contact)="{ item }">
                        <div v-if="item.contactThreadCartBox.contactThread && item.contactThreadCartBox.contactThread.contact">
                            <avatar :user="item.contactThreadCartBox.contactThread.contact" />
                        </div>
                        <feather-icon
                                v-else
                                icon="MinusIcon"
                        />
                    </template>

                    <template #cell(thread)="{ item }">
                        <div v-if="item.contactThreadCartBox.contactThread">
                            {{ item.contactThreadCartBox.contactThread.name }}
                        </div>
                        <feather-icon
                                v-else
                                icon="MinusIcon"
                        />
                    </template>

                    <template #cell(products)="{ item }">
                        <b-badge
                                variant="primary"
                                class="text-nowrap"
                        >
                            {{ `${$t('Products')}: ${item.productsCount || 0}` }}
                        </b-badge>
                    </template>

                    <template #cell(contactThreadCartBoxProduct)="{ item }">
                        {{ getTranslationsField(item.contactThreadCartBoxProduct) }}
                    </template>

                    <template #cell(nettoPrice)="{ item }">
              <span class="text-nowrap">
                {{ item.contactThreadCartBox.totalPriceWithDiscount | priceFormat }}
                PLN
              </span>
                    </template>

                    <template #cell(grossPrice)="{ item }">
              <span class="text-nowrap">
                {{ item.contactThreadCartBox.totalGrossPriceWithDiscount | priceGrossFormat }}
                PLN
              </span>
                    </template>

                    <template #cell(statusOfOrder)="{ item }">
                        <b-badge
                                variant="light-primary"
                                class="text-nowrap"
                        >
                            {{ $tc(`offer.status.${item.contactThreadCartBox.contactThreadCartOrder.status}`, 2) }}
                        </b-badge>
                    </template>

                    <template #cell(paymentMethod)="{ item }">
                        <b-badge
                                :variant="paymentTypeColors[item.contactThreadCartBox.paymentType]"
                                class="text-nowrap"
                        >
                            {{ $t(paymentTypes[item.contactThreadCartBox.paymentType]) }}
                        </b-badge>
                    </template>

                    <template #cell(commissionType)="{ value }">
                        <b-badge
                                variant="light-primary"
                                class="text-nowrap"
                        >
                            {{ $t(value) }}
                        </b-badge>
                    </template>

                    <template #cell(grossCommissionAmount)="{ item }">
              <span class="d-flex align-items-center">
                <span class="text-nowrap">
                  {{ item.value | priceFormat }}
                  PLN
                </span>

                <b-button
                        v-b-tooltip.hover.v-primary
                        :variant="item.status === 'REALIZED' ? 'flat-success' : 'flat-warning'"
                        size="sm"
                        class="btn-icon ml-25 p-25"
                        :title="$t(item.status === 'REALIZED' ? 'QualifiedCommission' : 'NotionalCommission')"
                >
                  <feather-icon :icon="item.status === 'REALIZED' ? 'CheckCircleIcon' : 'AlertCircleIcon'" />
                </b-button>
              </span>
                    </template>

                    <template #cell(toPaid)="{ value }">
              <span class="text-nowrap">
                {{ value | priceFormat }}
                PLN
              </span>
                    </template>
                    <template #cell(sellerDiscount)="{ value }">
              <span class="text-nowrap" v-if="value">
                {{ value | priceFormat }}
                PLN
              </span>
                        <feather-icon
                                v-else
                                icon="MinusIcon"
                        />
                    </template>

                    <template #cell(paid)="{ value }">
              <span class="text-nowrap">
                {{ value | priceFormat }}
                PLN
              </span>
                    </template>

                    <template #cell(paymentStatus)="{ item }">
                        <b-badge variant="light-primary">
                            {{ $t(`PaymentStatuses.${item.contactThreadCartBox.contactThreadCartOrder.paymentStatus}`) }}
                        </b-badge>
                    </template>

                    <template #cell(orderId)="{ item }">
                        <b-badge variant="light-primary">
                            {{ item.contactThreadCartBox.contactThreadCartOrder.number }}
                        </b-badge>
                    </template>

                    <template #cell(orderDate)="{ item }">
                        <span v-if="item.contactThreadCartBox && item.contactThreadCartBox.contactThreadCartOrder && item.contactThreadCartBox.contactThreadCartOrder.orderDate">{{ formatDate(item.contactThreadCartBox.contactThreadCartOrder.orderDate.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, $i18n.locale) }}</span>
                        <feather-icon
                                v-else
                                icon="MinusIcon"
                        />
                    </template>
                    <!--    Other    -->
                    <!--    Other    -->
                    <template #cell()="row">
                        <table-default-cell
                                :field="row.field"
                                :value="row.value"
                        />
                    </template>

                    <!--      Empty      -->
                    <template #empty>
                        <empty-content />
                    </template>

                    <!--      Table Busy      -->
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner
                                    class="align-middle"
                                    variant="primary"
                            />
                        </div>
                    </template>
                </b-table>
            </template>
        </sw-table>
    </b-card>
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import axiosIns from '@/libs/axios'
import moment from 'moment'
import XLSX from 'xlsx'

export default {
  name: 'SwCommissionTable',
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    status: {
      default: 'TO_PAY',
      type: String,
    },
    filters: {
      default: null,
      type: Object,
    },
    threadId: {
      type: String,
      required: false,
      default: null,
    },
    contactThread: {
      default: null,
      required: false,
    },
  },
  data: vm => ({
    paymentTypes: {
      ADVANCE: 'AdvancePayment',
      INSTALLMENT: 'Installment',
      TRANSFER: 'Przelew',
      CASH: 'Cash',
      LOYALTY_POINTS: 'LoyaltyPoints',
      TOTAL_PAYMENT: 'TotalAmount',
    },
    paymentTypeColors: {
      ADVANCE: 'info',
      TOTAL_PAYMENT: 'light-info',
      BANK_INSTALLMENT: 'warning',
      INSTALLMENT: 'light-warning',
      TRANSFER: 'primary',
      CASH: 'light-primary',
      LOYALTY_POINTS: 'light-success',
    },
    exportLoading: false,
    loading: false,
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      totalRecords: 0,
      currentPage: 1,
    },
    search: '',

    tableFields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      {
        key: 'orderId', label: 'OrderID', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_NUMBER],
      },
      {
        key: 'orderDate', label: 'OrderDate', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_ORDER_DATE],
      },
      {
        key: 'contact', label: 'Contact/Client', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_CONTACT],
      },
      {
        key: 'thread', label: 'Thread', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_THREAD],
      },
      {
        key: 'products', label: 'Products', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PRODUCTS],
      },
      {
        key: 'contactThreadCartBoxProduct', label: 'ProductEdit', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PRODUCT],
      },
      {
        key: 'sellerDiscount', label: 'UserDiscount', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_USER_DISCOUNT],
      },
      {
        key: 'nettoPrice', label: 'offer.NettoPrice', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_NETTO_PRICE],
      },
      {
        key: 'grossPrice', label: 'offer.GrossPrice', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_GROSS_PRICE],
      },
      {
        key: 'grossCommissionAmount', label: 'NetCommissionAmount', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_COMMISSION_AMOUNT],
      },
      {
        key: 'statusOfOrder', label: 'StatusOfOrder', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_ORDER_STATUS],
      },
      {
        key: 'paymentStatus', label: 'PaymentStatus', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PAYMENT_STATUS],
      },
      {
        key: 'paymentMethod', label: 'PaymentMethod', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_PAYMENT_METHOD],
      },
      {
        key: 'seller', label: 'Seller', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_SELLER],
      },
      {
        key: 'user', label: 'CommissionFor', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_COMMISSION_FOR],
      },
      {
        key: 'action', label: 'Action', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_COLUMN_ACTION],
      },
    ],
    points: [],
    agreementTypes: [],
  }),
  computed: {
    fields() {
      return this.tableFields.filter(field => field.visible)
        .filter(e => !e?.requiredViewRanks || this.checkRequiredViewPermissions(e.requiredViewRanks))
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        if (!this.loading) {
          this.loadData()
        }
      },
    },
    status(newValue, oldValue) {
      if (oldValue && newValue && oldValue !== newValue) {
        this.loadData()
      }
    },
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    formatDate,
    // eslint-disable-next-line consistent-return
    async loadData(pagination = null) {
      if (!pagination) {
        this.$emit('load-stats', this.search)
        this.loading = true
      }

      try {
        const params = {
          fields_load: this.$fields.MARKETING_PLAN_POINTS,
          orderBy: 'id.desc',
        }

        if (pagination) {
          params.page = pagination.currentPage
          params.limit = pagination.perPage
        } else if (this.pagination) {
          params.page = this.pagination.currentPage
          params.limit = this.pagination.perPage
        }

        if (this.contactThread) params['eq_contactThreadCartBox-contactThread-id'] = this.getObjectId(this.contactThread)

        if (this.filters?.seller?.length) params['eqArr_contactThreadCartBox-contactThreadCartOrder-seller-id'] = JSON.stringify(this.filters.seller.mapKeys())
        if (this.filters?.provisionFor?.length) params['eqArr_user-id'] = JSON.stringify(this.filters.provisionFor.mapKeys())
        if (this.filters?.paymentMethod) params['eq_contactThreadCartBox-paymentType'] = this.filters.paymentMethod
        if (this.filters?.orderStatus) params['eq_contactThreadCartBox-contactThreadCartOrder-status'] = this.filters.orderStatus
        if (this.filters?.orderCreatedAt) {
          const [startAt, endAt] = this.filters.orderCreatedAt.dateRange()
          if (startAt) params['gte_contactThreadCartBox-contactThreadCartOrder-createdAt'] = `${moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 00:00:00`
          if (endAt) params['lt_contactThreadCartBox-contactThreadCartOrder-createdAt'] = `${moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 23:59:59`
        }
        if (this.filters?.orderDate) {
          const [startAt, endAt] = this.filters.orderDate.dateRange()
          if (startAt) params['gte_contactThreadCartBox-contactThreadCartOrder-orderDate'] = `${moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 00:00:00`
          if (endAt) params['lt_contactThreadCartBox-contactThreadCartOrder-orderDate'] = `${moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 23:59:59`
        }

        if (this.search && this.search.length) {
          const searchString = this.search.replaceAll(' ', '%')
          params.search = `%${searchString}%`
          params.search_fields = 'contactThreadCartBox-contactThreadCartOrder-number,user-firstName,user-lastName,user-userId'
        }

        if (this.status) {
          params.eq_status = this.status
        }

        // if (this.threadId) {
        //   params['eq_loyaltyPlanPoints-contactThreadCartBox-contactThread-id'] = this.threadId
        // }

        // if (this.filters?.type) params.eq_status = this.filters.type
        // if (this.filters?.loyaltyPlan) params['eq_loyaltyPlan-id'] = this.getObjectId(this.filters.loyaltyPlan)
        // if (this.filters?.assignedUsers?.length) params['eqArr_user-id'] = JSON.stringify(this.filters?.assignedUsers.map(user => this.getObjectId(user)))
        // if (this.filters.assignedUsersStructure) params['eqArr_1_user-parentUser-id'] = JSON.stringify(this.filters?.assignedUsers.map(user => this.getObjectId(user)))
        // if (this.filters.createdAt)
        // if (this.filters.agreementDate)
        // if (this.filters.orderDate)

        if (this.sorter) {
          const sortDir = this.sorter?.sortDesc ? 'desc' : 'asc'
          const sortBy = this.sorter?.sortBy || 'id'
          params.orderBy = `${sortBy}.${sortDir}`
        }

        const resp = await axiosIns.get('1/marketingPlans/points', { params })

        if (!pagination) {
          this.points = resp.data?.data?.items ?? []
          this.pagination.totalRecords = resp.data.data.totalItemCount ?? 0
        }

        return resp.data
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },

    async getRecordsToExport() {
      this.exportLoading = true
      try {
        const chunksToLoad = Math.ceil(this.pagination.totalRecords / this.pagination.perPage)
        const requests = new Array(chunksToLoad).fill(null)
        const response = await Promise.all(requests.map((_, index) => this.loadData({ ...this.pagination, currentPage: index + 1 })))

        const items = []
        response.forEach(res => {
          items.push(...res.data?.items.map(order => {
            if (order.contactThreadCartBox.contactThreadCartOrder) {
              return {
                ...order.contactThreadCartBox.contactThreadCartOrder,
                ...order,
                orderStatus: order.contactThreadCartBox?.contactThreadCartOrder?.status,
                nettoPrice: this.$options.filters.priceFormat(order.contactThreadCartBox?.totalPriceWithDiscount),
                grossPrice: this.$options.filters.priceGrossFormat(order.contactThreadCartBox?.totalGrossPriceWithDiscount),
                contactThread: { ...(order.contactThreadCartBox?.contactThread || {}) },
              }
            }
            return {
              ...order,
              nettoPrice: this.$options.filters.priceFormat(order.contactThreadCartBox?.totalPriceWithDiscount),
              grossPrice: this.$options.filters.priceGrossFormat(order.contactThreadCartBox?.totalGrossPriceWithDiscount),
              contactThread: { ...(order.contactThreadCartBox?.contactThread || {}) },
            }
          }))
        })

        this.$nextTick(() => {
          this.exportRecords(items)
        })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    /* eslint-disable */
      async exportRecords(recordsList) {
          const wb = XLSX.utils.book_new()

          const order = [
              { key: 'id', label: '#',},
              { key: 'number', label: 'OrderID' },
              { key: 'orderDate', label: 'OrderDate' },
              { key: 'contact', label: 'Contact/Client' },
              { key: 'thread', label: 'Thread' },
              { key: 'contactThreadCartBoxProduct', label: 'ProductEdit' },
              { key: 'sellerDiscount', label: 'UserDiscount' },
              { key: 'nettoPrice', label: 'offer.NettoPrice' },
              { key: 'grossPrice', label: 'offer.GrossPrice' },
              { key: 'value', label: 'NetCommissionAmount' },
              { key: 'orderStatus', label: 'StatusOfOrder' },
              { key: 'paymentStatus', label: 'PaymentStatus' },
              { key: 'paymentMethod', label: 'PaymentMethod' },
              { key: 'seller', label: 'Seller' },
              { key: 'user', label: 'CommissionFor' },
          ]

          const aoaToExport = [
              ...recordsList.map(t => order.map(({ key }) => {
                  if (key === 'contact' && t?.contactThread?.contact) return this.getUserName(t.contactThread.contact)
                  if (key === 'thread' && t?.contactThread) return t.contactThread.name
                  if (key === 'contactThreadCartBoxProduct' && t?.contactThreadCartBoxProduct) return this.getTranslationsField(t.contactThreadCartBoxProduct, 'name')
                  if (key === 'seller' && t[key]) return this.getUserName(t[key])
                  if (key === 'user' && t[key]) return this.getUserName(t[key])
                  if (key === 'orderStatus' && t[key]) return this.$i18n.tc(`offer.status.${t[key]}`, 2)
                  if (key === 'paymentStatus' && t[key]) return this.$i18n.t(`PaymentStatuses.${t[key]}`)
                  if (key === 'paymentMethod' && t?.contactThreadCartBox) return this.$i18n.t(t.contactThreadCartBox.paymentType.toLowerCase())
                  if (typeof t[key] === 'boolean') return t[key] ? 1 : 0
                  if (t[key]?.date) return this.formatDate(t[key].date, { month: 'numeric', day: 'numeric', year: 'numeric' }, this.$i18n.locale)

                  return t[key] ?? ''
              }).flat(Infinity)),
          ]

          const headers = order.map(({ label }) => this.$i18n.t(label))
          aoaToExport.unshift(headers)

          const ws = XLSX.utils.aoa_to_sheet(aoaToExport);
          XLSX.utils.book_append_sheet(wb, ws, 'Commissions');
          XLSX.writeFile(wb, 'Commissions.xlsx');

          this.exportLoading = false
      },
  },
}
</script>

<style lang="scss">
</style>
