<template>
  <component
    :is="isMobile ? 'div' : 'app-timeline-item'"
    variant="success"
    icon="MailIcon"
    class="pb-2 d-flex flex-column justify-content-start align-items-start"
    style="row-gap: .34rem"
  >
    <slot />

    <component
      :is="isContact ? 'div' : 'b-card'"
      class="w-100 rounded-lg mb-0 position-relative"
      no-body
    >
      <b-card-body :class="`p-${ isContact ? '0' : '1' }`">
        <div
          class="d-flex mb-50"
          style="column-gap: .5rem"
        >
          <b-avatar
            v-if="isMobile"
            rounded="sm"
            size="sm"
            variant="light-success"
          >
            <span class="d-flex align-items-center">
              <feather-icon
                icon="MailIcon"
                size="14"
              />
            </span>
          </b-avatar>

          <router-link
            v-if="thread && !isContact"
            class="font-small-3"
            :class="{ 'deleted-text': thread.contact.deletedAt }"
            :to="`/contact/${thread.contact.id}/thread/${thread.id}/timeline`"
          >
            {{ thread.contact.firstName }} {{ thread.contact.lastName }} – {{ thread.name }}
          </router-link>

          <p
            v-else
            class="mb-0"
          >
            {{ $t('CurrentContact') }}
          </p>
        </div>

        <div
          v-if="thread"
          class="w-100 d-flex flex-row flex-wrap justify-content-between"
          style="column-gap: .64rem; row-gap: .34rem;"
        >
          <div
            class="d-flex flex-wrap flex-row align-items-center font-weight-bold"
            style="column-gap: .5rem; row-gap: .34rem;"
          >
            <b-badge
              v-if="automation && automation.automationProcedure"
              class="mr-25"
              variant="primary"
            >
              <feather-icon icon="CpuIcon" />
              {{ $t('Automation') }}: {{ automation.automationProcedure.name }}
            </b-badge>
            <b-badge variant="light-primary">
              <feather-icon icon="MailIcon" />
              {{ $t('MessageEmail') }}

              <feather-icon
                icon="ArrowRightIcon"
                class="ml-25 mr-50"
              />

              <feather-icon :icon="resolveFilterBadgeColor(data.status).icon" />
              {{ $t(`${resolveLang(data.status)}`) }}
            </b-badge>

            <div
              class="d-flex flex-row align-items-center"
              style="column-gap: .34rem"
            >
              <small class="text-lowercase">{{ $t('FromTheDay') }}</small>

              <span class="font-italic">{{ formatDate(timelineDate, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</span>
            </div>

            <avatar
              :user="data.createdBy"
              size="sm"
              show-tooltip
              :show-name="false"
            />
          </div>
        </div>

        <hr class="border-light-primary">

        <div
          class="w-100 d-flex flex-column justify-content-start align-items-start px-md-50"
          style="row-gap: .34rem"
        >
          <div
            class="w-100 d-flex flex-row flex-wrap align-items-center justify-content-between"
            style="column-gap: .5rem; row-gap: .34rem;"
          >
            <div
              class="d-flex flex-row flex-wrap align-items-center"
              style="column-gap: .5rem; row-gap: .34rem;"
            >
              {{ $t('From') }}

              <b-badge
                v-if="data.fromMail"
                variant="light-primary"
              >
                {{ data.fromMail }}
              </b-badge>

              <avatar
                v-else-if="data.createdBy"
                :user="data.createdBy"
                size="sm"
                class="mr-n25"
                show-tooltip
                :show-name="false"
              />

              <template v-if="data.toAddresses && data.toAddresses.length">
                {{ $t('To').toLowerCase() }}

                <div
                  class="d-flex flex-row flex-wrap align-items-center"
                  style="column-gap: .5rem; row-gap: .34rem;"
                >
                  <b-badge
                    v-for="address in data.toAddresses"
                    :key="`${address.id}_email`"
                    variant="light-primary"
                  >
                    {{ address.mail }}
                  </b-badge>
                </div>
              </template>

              <template v-if="data.ccAddresses && data.ccAddresses.length">
                {{ $t('Including').toLowerCase() }}

                <div
                  class="d-flex flex-row flex-wrap align-items-center"
                  style="column-gap: .5rem; row-gap: .34rem;"
                >
                  <b-badge
                    v-for="address in data.ccAddresses"
                    :key="`${address.id}_email`"
                    variant="light-primary"
                  >
                    {{ address.mail }}
                  </b-badge>
                </div>
              </template>
            </div>
          </div>

          <p class="h4 mb-0">
            {{ data.subject }}
          </p>

          <div
            v-if="data.contentHtml"
            class="spaceAll"
            v-html="getHtml(data.contentHtml)"
          />

          <documents-list
            v-if="data && Array.isArray(data.files) && data.files.length"
            :documents="data.files"
          />
        </div>
      </b-card-body>
    </component>

    <hr
      v-if="isContact"
      class="w-100 mb-0"
    >
  </component>
</template>

<script>
/* eslint-disable */
import { VBTooltip } from 'bootstrap-vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import DocumentsList from '@/views/components/DocumentsList.vue'
import * as emailStatuses from '@/@constants/emailStatuses'
import { formatDate } from '@core/utils/filter'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: 'TimelineMail',
  components: { AppTimelineItem, DocumentsList },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['data', 'timelineDate', 'threadId', 'thread', 'isContact', 'automation'],
  data: () => ({
    showMore: true,
  }),
  methods: {
    formatDate,
    getHtml(content = '') {
      const d = document.createElement('div')
      d.innerHTML = `<div>${content}</div>`
      if (d.innerHTML && d.querySelector('#saleswizard-content-html')) return d.querySelector('#saleswizard-content-html').innerHTML

      const formatedContent = content.replaceAll('\n', '<br />')
      return formatedContent
    },
  },
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const resolveFilterBadgeColor = filter => {
      if (filter === emailStatuses.RECEIVED) return { variant: 'primary', icon: 'InboxIcon' }
      if (filter === emailStatuses.QUEUE) return { variant: 'warning', icon: 'SendIcon' }
      if (filter === emailStatuses.DELIVERED) return { variant: 'success', icon: 'CheckSquareIcon' }
      if (filter === emailStatuses.FAILED) return { variant: 'danger', icon: 'AlertCircleIcon' }
      if (filter === emailStatuses.OPENED) return { variant: 'info', icon: 'FileTextIcon' }
      return 'primary'
    }

    const resolveLang = title => {
      if (title) {
        // eslint-disable-next-line no-underscore-dangle
        const _title = title.toLowerCase()
        return _title.charAt(0).toUpperCase() + _title.slice(1)
      }
      return ''
    }

    return {
      resolveFilterBadgeColor,
      resolveLang,
      isMobile,
    }
  },
}
</script>

<style lang="scss">
.pull-up {
  transition: all 0.25s ease !important;
}

.pl-0 .card .card-header {
  padding-left: 0 !important;
}

.spaceAll {
  max-width: 100%;
  width: 100%;
  overflow-wrap: break-word !important;
  white-space: normal !important;
  word-break: break-word !important;
  * {
    max-width: 100%;
    width: 100%;
    overflow-wrap: break-word !important;
    white-space: normal !important;
    word-break: break-word !important;
  }
}
</style>
