<template>
  <component
    :is="isMobile ? 'div' : 'app-timeline-item'"
    variant="success"
    icon="CreditCardIcon"
    class="pb-2 d-flex flex-column justify-content-start align-items-start position-relative"
    style="row-gap: .34rem"
  >
    <slot />

    <component
      :is="isContact ? 'div' : 'b-card'"
      class="w-100 rounded-lg mb-0 position-relative"
      no-body
    >
      <b-card-body :class="`p-${ isContact ? '0' : '1' }`">
        <div
          class="d-flex"
          style="column-gap: .5rem"
        >
          <b-avatar
            v-if="isMobile"
            rounded="sm"
            size="sm"
            variant="light-success"
          >
            <span class="d-flex align-items-center">
              <feather-icon
                icon="CreditCardIcon"
                size="14"
              />
            </span>
          </b-avatar>

          <router-link
            v-if="thread && !isContact"
            class="font-small-3"
            :class="{ 'deleted-text': thread.contact.deletedAt }"
            :to="`/contact/${thread.contact.id}/thread/${thread.id}/timeline`"
          >
            {{ thread.contact.firstName }} {{ thread.contact.lastName }} – {{ thread.name }}
          </router-link>

          <p
            v-else
            class="mb-0"
          >
            {{ $t('CurrentContact') }}
          </p>
        </div>

        <div class="w-100 font-weight-bold">
          <div
            v-if="thread"
            class="w-100 d-flex flex-row flex-wrap justify-content-between"
            style="column-gap: .64rem; row-gap: .34rem;"
          >
            <div
              class="d-flex flex-wrap flex-row align-items-center"
              style="column-gap: .5rem; row-gap: .34rem;"
            >
              <b-badge
                v-if="automation && automation.automationProcedure"
                class="mr-25"
                variant="primary"
              >
                <feather-icon icon="CpuIcon" />
                {{ $t('Automation') }}: {{ automation.automationProcedure.name }}
              </b-badge>

              <b-badge
                variant="light-primary"
                class="d-flex flex-wrap flex-row align-items-center"
                style="row-gap: .34rem;"
              >
                <div
                  class="d-flex flex-wrap flex-row align-items-center"
                  style="row-gap: .34rem; column-gap: .34rem;"
                >
                  <feather-icon icon="CreditCardIcon" />
                  {{ $t('Order') }}
                </div>

                <feather-icon
                  icon="ArrowRightIcon"
                  class="ml-25 mr-50"
                />

                <div
                  class="d-flex flex-wrap flex-row align-items-center"
                  style="row-gap: .34rem; column-gap: .34rem;"
                >
                  <feather-icon icon="StarIcon" />
                  {{ offerLabel }}
                </div>
              </b-badge>

              <div
                class="d-flex flex-row align-items-center"
                style="column-gap: .5rem; row-gap: .34rem;"
              >
                <small class="text-lowercase">{{ $t('FromTheDay') }}</small>

                <span class="font-italic">{{ formatDate(timelineDate, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</span>
              </div>

              <avatar
                :user="data.createdBy"
                size="sm"
                show-tooltip
                :show-name="false"
                class="mr-n25"
              />
            </div>
          </div>
        </div>

        <hr class="border-light-primary">

        <app-collapse
          class="pl-0 w-100 px-md-50"
          hover
        >
          <app-collapse-item
            :title="$t('ShowDetails')"
            :is-padding="false"
            arrow-left
            :is-title="false"
          >
            <template #subTitle>
              <div
                class="d-flex flex-wrap flex-column-reverse flex-md-row"
                style="row-gap: .34rem; column-gap: .5rem;"
              >
                <strong>{{ $t('ShowDetails') }}</strong>

                <div
                  class="d-flex flex-wrap"
                  style="row-gap: .34rem; column-gap: .5rem;"
                >
                  <b-badge variant="light-primary">
                    ID:

                    {{ data.number }}
                  </b-badge>

                  <b-badge variant="light-primary">
                    {{ $t(`GrossSum`) }}

                    {{ data.priceGrossEnd || 0 | priceFormat }} PLN
                  </b-badge>
                </div>
              </div>
            </template>

            <product-specification
              type="ORDER"
              :offer="data"
              :show-images="false"
            />
          </app-collapse-item>
        </app-collapse>
      </b-card-body>
    </component>

    <hr
      v-if="isContact"
      class="w-100 mb-0"
    >
  </component>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import smsStatus from '@/helpers/smsStatuses'
import { formatDate } from '@core/utils/filter'
import ProductSpecification from '@/views/offers/product/ProductSpecification.vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: 'Offer',
  components: { ProductSpecification, AppTimelineItem },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['data', 'timelineDate', 'threadId', 'thread', 'action', 'isContact', 'automation'],
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isMobile,
    }
  },
  computed: {
    offerLabel() {
      let label = ''
      if (this.action === 'open') label = 'OrderAdded'
      else if (this.action === 'add') label = 'OrderAdded'
      else if (this.action === 'changeStatus') label = 'OrderChangeStatus'
      else if (this.action === 'accept') label = 'OrderChangeStatus'
      else if (this.action === 'expire') label = 'OrderChangeStatus'
      return this.$i18n.t(`task.Labels.${label}`)
    },
  },
  methods: {
    smsStatus,
    formatDate,
  },
}
</script>

<style lang="scss">
.pl-0 .card .card-header {
  padding-left: 0 !important;
}
</style>
