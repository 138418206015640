<template>
  <div>
    <!-- eslint-disable -->
    <sw-table
      table-id="2131865cd847408db4281636cbcb1d61"
      show-columns
      show-search
      :fields.async="offerFields"
      :pagination="pagination"
      @change-pagination="Object.assign(pagination, $event)"
      @change-search-query="searchQuery = $event"
      @reload-content="loadOffers"
      @set-fields="offerFields = $event"
    >
      <template #table>
        <!--      Table      -->
        <b-table
          :items="offers"
          class="offer-table"
          :fields="fields"
          striped
          responsive
          :busy="loading"
          show-empty
          :sort-by.sync="sorter.sortBy"
          :sort-desc.sync="sorter.sortDesc"
          :no-local-sorting="true"
          @sort-changed="Object.assign(sorter, $event); loadOffers()"
        >
          <!--    Head    -->
          <template #head()="{ label }">
            {{ $t(`${label}`) }}
          </template>
            <template #cell(id)="{ index }">
                #{{ getOrderNo(index, pagination, sorter) }}
            </template>

          <!--    Name    -->
          <template #cell(name)="{ item }">
            <span
              v-b-tooltip.hover.left
              :title="item.contactThreadCartBox.name"
              class="doubleLineText text-primary"
            >
              {{ item.contactThreadCartBox.name | truncate(26, '...') }}
            </span>
          </template>

          <!--    Status    -->
          <template #cell(status)="{ item }">
            <!--  && isSomeAgreementAccepted(item.contactThreadAgreements)-->
            <b-badge
              variant="light-primary"
            >
              {{ $tc(`offer.status.${getOfferStatus(item)}`, 1) }}
            </b-badge>
          </template>

          <!--    contactThreadOfferProducts    -->
          <template #cell(contactThreadOfferProducts)="{ value, index, item }">
            <b-button
              :id="`popover-target_${index}`"
              href="#"
              tabindex="0"
              size="sm"
              variant="outline-primary"
              style="min-width: 125px"
              @click="offerSpec = item"
            >
              {{ $t('Offer.Products') + ': ' + (item.contactThreadCartBox.contactThreadCartBoxProducts || []).length }}
            </b-button>
          </template>

          <!--    Message Status    -->
          <template #cell(lastMail)="{ value }">
            <span class="d-flex align-items-center justify-content-center">
              <!--    Variant for Sent    -->
              <b-badge
                v-if="value && value.status"
                :variant="resolveEmailStatusColor(value.status)"
              >
                {{ $t(`${title((value.status || '').toLowerCase())}`) }}
              </b-badge>
              <b-badge v-else>
                <feather-icon icon="MinusIcon" />
              </b-badge>
            </span>
          </template>

          <!--    Nett Price    -->
          <template #cell(nettPrice)="{ item }">
            {{ (item.priceWithDiscount || 0) | priceFormat }} PLN
          </template>
          <!-- priceGrossEnd -->
          <template #cell(priceGrossEnd)="{ value }">
            <span class="text-nowrap">
              {{ value || 0 | priceGrossFormat }}
              PLN
            </span>
          </template>

          <!-- validityDays -->
          <template #cell(offerValidityDays)="{ item }">
            <span>{{ item.offerValidityDays || 0 }} {{ $tc('Days', item.offerValidityDays || 0) }}</span>
          </template>

          <!--    isPDF    -->
          <template #cell(isPDF)="{ value }">
            <span class="d-flex justify-content-center">
              <b-badge
                v-if="value"
                v-b-tooltip.hover.v-success
                variant="light-success"
                pill
                :title="$t('Active')"
              >
                <feather-icon icon="CheckIcon" />
              </b-badge>
              <b-badge
                v-else
                v-b-tooltip.hover.v-danger
                variant="light-danger"
                pill
                :title="$t('InActive')"
              >
                <feather-icon icon="MinusIcon" />
              </b-badge>
            </span>
          </template>

          <!--    isWWW    -->
          <template #cell(isWWW)="{ item }">
            <span class="d-flex justify-content-center">
              <div>
                <a
                  v-if="item.offerWebTemplate"
                  v-b-tooltip.hover
                  class="badge badge-success"
                  :title="item.offerWebTemplate.name"
                  :href="`${item.offerWebUrlLoggedUser}`"
                  target="_blank"
                >
                  <feather-icon icon="ExternalLinkIcon" />
                </a>
                <b-badge
                  v-else
                  variant="light-danger"
                  pill
                >
                  <feather-icon icon="MinusIcon" />
                </b-badge>
              </div>

              <div>
                <a
                  v-if="item.offerWebTemplate"
                  v-b-tooltip.hover
                  class="badge badge-primary ml-25 d-flex align-items-center"
                  :title="item.offerWebTemplate.name"
                  :href="`${item.offerWebUrlContact}`"
                  target="_blank"
                >
                  <feather-icon icon="Link2Icon" />
                  <div class="ml-25">{{ $t('ClientLink') }}</div>
                </a>
                <b-badge
                  v-else
                  variant="light-danger"
                  pill
                >
                  <feather-icon icon="MinusIcon" />
                </b-badge>
              </div>
            </span>
          </template>

          <!-- messages -->
          <template #cell(messages)="{ item }">
            <b-badge
              v-if="item.contactThreadCartOfferMessages && item.contactThreadCartOfferMessages.length"
              class="cursor-pointer"
              @click="offerSpecMessages = item"
            >
              {{ item.contactThreadCartOfferMessages.length }}
            </b-badge>
            <b-badge
              v-else
              variant="light-secondary"
            >
              0
            </b-badge>
          </template>

          <!-- Files -->
          <template #cell(files)="{ item }">
            <documents-list
              v-if="item.contactThreadCartBox.files && item.contactThreadCartBox.files.length"
              :documents="item.contactThreadCartBox.files"
            />
            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </template>

          <!--    isAgreement    -->
          <template #cell(isAgreement)="{ value }">
            <span class="d-flex justify-content-center">
              <b-badge
                v-if="value"
                v-b-tooltip.hover.v-success
                variant="light-success"
                pill
                :title="$t('Active')"
              >
                <feather-icon icon="CheckIcon" />
              </b-badge>
              <b-badge
                v-else
                v-b-tooltip.hover.v-danger
                variant="light-danger"
                pill
                :title="$t('InActive')"
              >
                <feather-icon icon="MinusIcon" />
              </b-badge>
            </span>
          </template>
          <!--    sendEmail    -->
          <template #cell(contactThreadCartOfferMails)="{ value, item, index }">
            <span class="d-flex justify-content-center">
              <b-badge
                :id="`mails_${item.id}_${index}`"
                class="d-flex align-items-center"
                variant="light-primary"
                pill
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  size="10"
                  class="mr-25"
                />
                {{ getDeliveredMessagesCount(value).length }} / {{ value.length }}
              </b-badge>
              <b-popover
                v-if="value.length"
                :target="`mails_${item.id}_${index}`"
                triggers="hover"
              >
                <div
                  v-for="(contactThreadMail, mailIndex) in value"
                  :key="`mails_${item.id}_${index}_${mailIndex}`"
                  class="text-center"
                >
                  <avatar
                    :to="{ name:'contact', params: { contactId: contactThreadMail.contact.id }}"
                    :user="contactThreadMail.contact"
                  />
                  <div><small><b-badge
                    class="w-100"
                    block
                    :variant="resolveEmailStatusColor(contactThreadMail.mail.status)"
                  >{{ $t(`${title(contactThreadMail.mail.status.toLowerCase())}`) }}</b-badge></small></div>
                  <hr>
                </div>
              </b-popover>
            </span>
          </template>
          <!--    allowElectronicSignature    -->
          <template #cell(allowElectronicSignature)="{ item }">
            <div>
              <span class="d-flex justify-content-center">
                <b-badge
                  v-b-tooltip.hover
                  :variant="item.contactThreadCart.allowElectronicSignature ? 'light-success' : 'light-danger'"
                  pill
                  :title="item.contactThreadCart.allowElectronicSignature ? $t('Active') : $t('InActive')"
                >
                  <feather-icon
                    v-if="item.contactThreadCart.allowElectronicSignature"
                    icon="CheckIcon"
                  />
                  <feather-icon
                    v-else
                    icon="MinusIcon"
                  />
                </b-badge>
              </span>
            </div>
          </template>

          <!--    Documents    -->
          <template #cell(documents)="{ value, item }">
            <b-button
              :id="`popover-target_doc_${item.lp}`"
              href="#"
              tabindex="0"
              size="sm"
              variant="primary"
            >
              {{ $t('Offer.Products') + ': ' + value.length }}
            </b-button>
            <b-popover
              :target="`popover-target_doc_${item.lp}`"
              triggers="focus"
              placement="right"
            >
              <div class="d-inline-flex row justify-content-center px-1 tablePopover">
                <span
                  v-for="(option, index) in value"
                  :key="index"
                  class="border-bottom w-100 px-1 py-25 text-center"
                >
                  {{ option.name }}
                </span>
              </div>
            </b-popover>
          </template>

          <!--    Table Action    -->
          <template #cell(action)="{ item, index }">
            <b-dropdown
              class="dropdown-icon-wrapper text-decoration-none"
              dropleft
              no-caret
              variant="flat"
            >
              <template
                #button-content
                class="sr-only"
              >
                <feather-icon icon="MoreVerticalIcon" />
              </template>

              <!--    Action: Duplicate    -->
              <b-dropdown-item
                v-if="item.status !== 'CANCELLED' && item.status !== 'ACCEPTED' && checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_ACCEPT])"
                @click="changeStatus(item, 'ACCEPTED', index)"
              >
                <dropdown-item-label icon="CheckIcon" :label="$t('Accept1')" />
              </b-dropdown-item>

                <!--    Action: Finish    -->
                <b-dropdown-item
                        v-if="item.status !== 'CANCELLED' && item.status !== 'ACCEPTED' && checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_EDIT])"
                        @click="finishOffer(item, index)"
                >
                    <dropdown-item-label icon="FlagIcon" :label="$t('Finish')" />
                </b-dropdown-item>

                <!--    Action: Extend    -->
                <b-dropdown-item
                        v-if="item.status !== 'CANCELLED' && item.status !== 'ACCEPTED' && checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_EDIT])"
                        @click="extendOffer(item, index)"
                >
                    <dropdown-item-label icon="ClockIcon" :label="$t('Extend')" />
                </b-dropdown-item>

                <!--    Action: Duplicate    -->
                <b-dropdown-divider v-if="item.status !== 'CANCELLED' && item.status !== 'ACCEPTED'" />

                <b-dropdown-item
                        v-if="item.status !== 'CANCELLED' && checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_ADD])"
                        @click="duplicateOffer(item, index)"
                >
                    <dropdown-item-label icon="CopyIcon" :label="$t('Duplicate')" />
                </b-dropdown-item>

              <!--    Action: Send To Client    -->
              <b-dropdown-item
                v-if="item.status !== 'CANCELLED' && item.status !== 'ACCEPTED'"
                v-b-tooltip
                :title="item.contactThreadCartBox.contactThread && !item.contactThreadCartBox.contactThread.contact.email ? $t('ContactDontHaveAssignedEmailToSendOffer') : ''"
                :disabled="item.contactThreadCartBox.contactThread && !item.contactThreadCartBox.contactThread.contact.email"
                @click="sendOfferToClient(item, index)"
              >
                <dropdown-item-label icon="SendIcon" :label="$t('Offer.SendToClient')" />
              </b-dropdown-item>

              <!--    Action: Add Contract    -->
              <b-dropdown-item
                v-if="checkRequiredModule('agreementModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_AGREEMENT_ADD])"
                @click="openAgreementModal(item)"
              >
                <dropdown-item-label icon="FilePlusIcon" :label="$t('Contracts.AddContract')" />
              </b-dropdown-item>

              <!--    Action: Send To Client    -->
              <b-dropdown-item
                  v-if="checkRequiredModule('orderModule')"
                :disabled="!!item.contactThreadCartBox.contactThreadCartOrder"
                @click="offerToConvert = item"
              >
                <dropdown-item-label icon="ShoppingBagIcon" :label="$t('ConvertToOrder')" />
              </b-dropdown-item>


                <span v-if="item.status !== 'CANCELLED' && item.status !== 'ACCEPTED'">
                  <b-dropdown-divider />
                    <!--    Action: Messages    -->
                  <b-dropdown-item @click="offerSpecMessages = item">
                      <dropdown-item-label icon="MessageCircleIcon" :label="$t('OffersMessage')" />
                  </b-dropdown-item>

                      <!--    Action: Specification    -->
                  <b-dropdown-item @click="offerSpec = item">
                      <dropdown-item-label icon="EyeIcon" :label="$t('Specification')" />
                  </b-dropdown-item>
                </span>

            </b-dropdown>
          </template>

          <!--    Creator    -->
          <template #cell(createdBy)="{ value }">
            <avatar
              :user="value"
              :to="{ name: 'user', params: { userId: value.id } }"
            />
          </template>

          <!-- Contact -->
          <template #cell(contact)="{ item }">
            <span v-if="item.contactThreadCartBox.contactThread">
              <router-link  :class="{ 'deleted-text': item.contactThreadCartBox.contactThread.contact.deletedAt }" :to="`/contact/${item.contactThreadCartBox.contactThread.contact.id}`">
                {{ item.contactThreadCartBox.contactThread.contact.firstName }} {{ item.contactThreadCartBox.contactThread.contact.lastName }}
              </router-link>
            </span>
            <span v-else>
              <feather-icon icon="MinusIcon" />
            </span>
          </template>

          <!-- Thread -->
          <template #cell(thread)="{ item }">
            <span v-if="item.contactThreadCartBox.contactThread">
              <router-link :class="{ 'deleted-text': item.contactThreadCartBox.contactThread.contact.deletedAt }" :to="`/contact/${item.contactThreadCartBox.contactThread.contact.id}/thread/${item.contactThreadCartBox.contactThread.id}/offers`">
                {{ item.contactThreadCartBox.contactThread.name | truncate(20) }}
              </router-link>
            </span>
            <span v-else>
              <feather-icon icon="MinusIcon" />
            </span>
          </template>

          <template #cell(agreements)="row">
            <!--        <documents-list :documents="item.contactThreadAgreements.map(item => item.filePdf)" />-->
            <b-button
              size="sm"
              variant="primary"
              class="d-flex align-items-center px-50 py-25"
              @click="row.toggleDetails"
            >
              <b-badge
                variant="light"
                class="text-primary"
              >
                {{ (row.item.contactThreadCartBox.contactThreadAgreements || []).length }}
              </b-badge>
              <feather-icon
                class="ml-50"
                :icon="row.detailsShowing ? 'MinusIcon' : 'PlusIcon'"
              />
            </b-button>
          </template>
          <template #row-details="{ item }">
            <div v-if="item.contactThreadCartBox.contactThreadAgreements">
              <b-card
                v-for="(agreementItem, index) in item.contactThreadCartBox.contactThreadAgreements"
                :key="`agreement_${item.id}_item_${index}`"
                class="py-0"
              >
                <div class="d-flex align-items-center">
                  <div
                    style="min-width: 160px"
                    class="text-truncate"
                  >
                    <div class="font-weight-bolder">
                      {{ $t('Status') }}
                    </div>
                    <div>
                      <b-badge variant="light-primary">
                        {{ $t(`AgreementStatuses.${agreementItem.status}`) }}
                      </b-badge>
                    </div>
                  </div>
                  <div
                    v-if="agreementItem.signedAt"
                    style="min-width: 160px"
                    class="text-truncate"
                  >
                    <div class="font-weight-bolder">
                      {{ $t('Contracts.DateOfSigningContract') }}
                    </div>
                    <div>
                      <b-badge variant="light-primary">
                        {{ formatDate(agreementItem.signedAt.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, $i18n.locale) }}
                      </b-badge>
                    </div>
                  </div>
                  <div
                    v-if="(agreementItem.agreementTemplate && agreementItem.agreementTemplate.agreementType) || agreementItem.agreementType"
                    style="min-width: 200px; max-width: 250px;"
                    class="text-truncate"
                  >
                    <div class="font-weight-bolder">
                      {{ $t('Contracts.AgreementType') }}
                    </div>
                    <div v-if="agreementItem.agreementType">{{ agreementItem.agreementType.name }}</div>
                    <div v-else>{{ agreementItem.agreementTemplate.agreementType.name }}</div>
                  </div>
                  <div
                    v-if="agreementItem.filesPdf && agreementItem.filesPdf.length"
                    style="min-width: 200px; max-width: 300px; margin-right: 20px"
                    class="text-truncate"
                  >
                    <div class="font-weight-bolder">
                      {{ $t('Contracts.FileTypes.Agreement') }}
                    </div>
                    <div
                      v-for="(filePdf, index) in agreementItem.filesPdf"
                      :key="`file_pdf_${index}_${item.id}`"
                      class="cursor-pointer mt-25"
                      @click="downloadPdf(filePdf.token, filePdf.name)"
                      v-b-tooltip
                      :title="filePdf.name"
                    >
                      <feather-icon
                        icon="FileIcon"
                      />
                      {{ filePdf.name | truncate(40) }}
                    </div>
                  </div>
                  <div
                    style="min-width: 200px"
                    class="text-truncate"
                  >
                    <div class="font-weight-bolder">
                      {{ $t('SignerUser') }}
                    </div>
                    <avatar
                      v-if="agreementItem.signerUser"
                      :user="agreementItem.signerUser"
                      :to="{ name: 'user', params: { userId: agreementItem.signerUser.id } }"
                    />
                    <feather-icon
                      v-else
                      icon="MinusIcon"
                    />
                  </div>
                  <div
                    style="min-width: 205px"
                    class="text-truncate"
                  >
                    <div class="font-weight-bolder">
                      {{ $t('Signature') }}
                    </div>
                    <div>
                      <b-form-checkbox
                        disabled
                        variant="primary"
                        :checked="agreementItem.allowElectronicSignature"
                      >
                        <span v-if="agreementItem.electronicSignatureType">
                            {{ $t(`signatureTypes.${agreementItem.electronicSignatureType}`) }}
                        </span>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div
                    v-if="agreementItem.filesSigned && agreementItem.filesSigned.length"
                    style="min-width: 150px"
                    class="text-truncate"
                  >
                    <div class="font-weight-bolder">
                      {{ $t('contact.agreement') }}
                    </div>
                    <div
                      v-for="(fileSigned, index) in agreementItem.filesSigned"
                      :key="`file_signed_${index}_${item.id}`"
                      class="cursor-pointer mt-25"
                      @click="downloadPdf(fileSigned.token, fileSigned.name)"
                    >
                      <feather-icon
                        icon="FileIcon"
                      />
                      {{ fileSigned.name }}
                    </div>
                  </div>
                  <div class="pr-2">
                    <!--                              v-if="agreementItem.status === 'SIGNING'"-->
                    <div
                      v-b-tooltip
                      :title="agreementItem.allowElectronicSignature && agreementItem.status === 'SIGNING' ? $t('AutentiSignWasInitialized') : ''"
                    >
                      <b-button
                        v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_AGREEMENT_EDIT])"
                        variant="success"
                        size="sm"
                        class="mr-25"
                        :disabled="agreementItem.status !== 'NEW' || agreementItem.signedAt"
                        @click="signAgreement(agreementItem, item.id)"
                      >
                        <div v-if="agreementItem.status !== 'NEW' || agreementItem.signedAt">
                          <feather-icon :icon="agreementItem.allowElectronicSignature && agreementItem.status === 'SIGNING' ? 'Edit2Icon' : 'CheckIcon'" />
                        </div>
                        <div v-else>
                          {{ agreementItem.allowElectronicSignature ? $t('SignedManually') : $t('Accept1') }}
                        </div>
                      </b-button>
                    </div>
                    <!--              <b-button-->
                    <!--                v-if="agreementItem.status === 'SIGNING'"-->
                    <!--                variant="warning"-->
                    <!--                size="sm"-->
                    <!--              >-->
                    <!--                {{ $t('Remind') }}-->
                    <!--              </b-button>-->
                  </div>
                </div>
              </b-card>
            </div>
          </template>
          <!--    Other    -->
            <!--    Other    -->
            <template #cell()="row">
                <table-default-cell
                        :field="row.field"
                        :value="row.value"
                />
            </template>

            <!--      Empty      -->
            <template #empty>
                <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner
                            class="align-middle"
                            variant="primary"
                    />
                </div>
            </template>
        </b-table>
      </template>
    </sw-table>

    <!-- Spec Modal -->
    <b-modal
      size="lg"
      :visible="!!offerSpec"
      :title="offerSpec ? `${$t('Offer.Cart')}: ${offerSpec.contactThreadCartBox.name}` : $t('Specification')"
      button-size="sm"
      ok-only
      :ok-title="$t('Close')"
      ok-variant="secondary"
      @hide="offerSpec = null"
    >
      <product-specification
        v-if="offerSpec"
        :offer="offerSpec"
      />
    </b-modal>

    <!-- Extend offer -->
    <b-modal
      v-if="!!offerToExtend"
      :visible="!!offerToExtend"
      :title="offerToExtend ? `${$t('offer.Offer')}: ${offerToExtend.name}` : $t('Specification')"
      button-size="sm"
      :ok-title="$t('Save')"
      :cancel-title="$t('Cancel')"
      ok-variant="primary"
      :ok-disabled="!offerToExtend.offerValidityDays || offerToExtend.offerValidityDaysMin > offerToExtend.offerValidityDays"
      @ok="saveExtendedOffer"
      @hide="offerToExtend = null"
    >
      <b-form-group
        :label="$t('ExtendValidityOfOfferBy')"
      >
        <b-input-group
          class="input-group-merge"
          :title="$t('ExtendValidityOfOfferBy')"
        >
          <b-form-input
            v-model="extendValidityDays"
            type="number"
            class="form-control-merge"
          />
          <b-input-group-append is-text>
            {{ $tc('Days', extendValidityDays) }}
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-modal>

    <!-- Extend offer -->
    <b-modal
      size="lg"
      :visible="!!offerSpecMessages"
      :title="offerSpecMessages ? `${$t('OffersMessage')}: ${offerSpecMessages.contactThreadCartBox.name}` : $t('OffersMessage')"
      button-size="sm"
      ok-only
      :ok-title="$t('Close')"
      ok-variant="secondary"
      @hide="offerSpecMessages = null"
    >
      <offer-messages
        v-if="offerSpecMessages"
        :offer="offerSpecMessages"
      />
    </b-modal>

    <!-- Add Agreement -->
    <b-modal
      size="lg"
      :visible="!!offerAgreement"
      :title="$t('Contracts.AddContract')"
      hide-footer
      no-close-on-backdrop
      @hide="offerAgreement = null"
    >
      <b-tabs v-if="offerAgreement">
        <b-tab :title="$t('SystemAgreement')">
          <section-agreement-item
            v-if="offerAgreement"
            :agreement="agreement"
            :autenti-enabled="autentiEnable"
            :is-cart="false"
            :agreement-types="agreementTypes.filter(e => e.agreementTemplates  && e.agreementTemplates.length)"
            :cart-item="offerAgreement"
            @close-modal="offerAgreement = null; agreement = {...emptyAgreement}; getSingleOffer($event.offerId)"
          />
        </b-tab>

        <b-tab :title="$t('File')">
            <b-form-group :label="$t('Contracts.AgreementType')">
                <v-select
                        v-model="agreement.agreementType"
                        :options="agreementTypes"
                        :reduce="item => item.id"
                        label="name"
                />
            </b-form-group>
          <sw-select :name="$t('Contracts.DateOfSigningContract')">
            <sw-select-fpr>
              <flat-pickr
                ref="timeRange"
                v-model="agreement.signedAt"
                class="form-control"
                :config="{ dateFormat: 'd-m-Y', locale: $i18n.locale }"
              />
            </sw-select-fpr>
          </sw-select>

          <drag-drop-upload
            @onChangeFiles="(e) => { $set(agreement, 'filesSigned', e) }"
          />

          <b-button
            size="sm"
            class="mt-50"
            variant="primary"
            :disabled="!agreement.signedAt || !agreement.filesSigned.length || agreementLoading"
            @click="updateAgreement({ ...agreement, id: offerAgreement.id, contactId: offerAgreement.contactId, threadId: offerAgreement.threadId }, offerAgreement.offerId, 'add')"
          >
            {{ $t('Save') }}
          </b-button>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal
      size="lg"
      :visible="!!offerToConvert"
      :title="$t('ConvertToOrder')"
      hide-footer
      no-close-on-backdrop
      @hide="offerToConvert = null"
    >
      <offer-to-order
        v-if="offerToConvert"
        :offer="offerToConvert"
        @close-modal="getSingleOffer(offerToConvert.id); offerToConvert = null"
      />
    </b-modal>

    <b-modal
      size="lg"
      :visible="!!offerAgreementSignManually"
      :title="$t('CreateContract')"
      hide-footer
      no-close-on-backdrop
      @hide="offerAgreementSignManually = null"
    >
      <div v-if="offerAgreementSignManually">
        <sw-select :name="$t('Contracts.DateOfSigningContract')">
          <sw-select-fpr>
            <flat-pickr
              ref="timeRange"
              v-model="offerAgreementSignManually.signedAt"
              class="form-control"
              :config="{ dateFormat: 'd-m-Y', locale: $i18n.locale }"
            />
          </sw-select-fpr>
        </sw-select>

        <drag-drop-upload
                v-if="offerAgreementSignManually.status !== 'ACCEPTED'"
          @onChangeFiles="(e) => { offerAgreementSignManually.filesSigned.push(...e) }"
        />

        <b-button
          size="sm"
          class="mt-50"
          variant="primary"
          :disabled="!offerAgreementSignManually.signedAt || agreementLoading"
          @click="updateAgreement(offerAgreementSignManually, offerAgreementSignManually.offerId)"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BInputGroupAppend, BModal, BPopover, BTab, BTable, BTabs, VBTooltip,
} from 'bootstrap-vue'
import offerStatus from '@/helpers/offer/offerStatus'
import messageStatus from '@/helpers/offer/messageStatus'
import {
  ADD_PRODUCT_TO_CART,
  DOWNLOAD_FILE,
  GET_OFFER,
  GET_OFFERS,
  GET_PRIVATE_SETTINGS,
  GET_THREAD_OFFERS,
  REMOVE_PRODUCT_FROM_CART,
  UPDATE_CART,
  UPDATE_OFFER,
  UPLOAD_FILES,
} from '@/@constants/mutations'
import ProductSpecification from '@/views/offers/product/ProductSpecification.vue'
import OfferMessages from '@/views/offers/OfferMessages.vue'
import { formatDate, title } from '@core/utils/filter'
import resolveEmailStatusColor from '@/helpers/emailStatuses'
import axiosIns from '@/libs/axios'
import DocumentsList from '@/views/components/DocumentsList.vue'
import moment from 'moment'
import OfferToOrder from '@/pages/offer/OfferToOrder.vue'
import SectionAgreementItem from '@/views/offers/checkout/SectionAgreementItem.vue'
import flatPickr from 'vue-flatpickr-component'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import vSelect from 'vue-select'
import { priceGrossFormat } from '@core/filters/parts/valueFormat'

const emptyAgreement = {
  agreementType: '',
  agreementTemplate: '',
  allowElectronicSignature: false,
  anonymised: false,
  signerUser: '',
  signedAt: '',
  filesSigned: [],
  contactThreadCartDraftAgreementAssignedContacts: [],
}

export default {
  components: {
    vSelect,
    BTabs,
    BTab,
    OfferToOrder,
    SectionAgreementItem,
    DocumentsList,
    BModal,
    BTable,
    BPopover,
    BInputGroupAppend,
    flatPickr,
    DragDropUpload,
    OfferMessages,
    ProductSpecification,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    threadId: {
      type: String,
      default: null,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['status'],
  data: () => ({
    emptyAgreement,
    offerStatus: (offerStatus)(),
    messageStatus: (messageStatus)(),
    agreement: { ...emptyAgreement },
    autentiEnable: false,
    offerSpec: null,
    offerSpecMessages: null,
    offerAgreement: null,
    offerToConvert: null,
    offerAgreementSignManually: null,
    agreementLoading: false,

    extendValidityDays: 0,

    loading: false,
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      totalRecords: 0,
      currentPage: 1,
    },
    searchQuery: '',

    offerFields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      { key: 'createdAt', label: 'CreationTime', visible: true },
      { key: 'contact', label: 'Contact', visible: true },
      { key: 'thread', label: 'contact.contactThread', visible: true },
      { key: 'name', label: 'Name', visible: true },
      { key: 'status', label: 'Status', visible: true },
      { key: 'contactThreadOfferProducts', label: 'Contents', visible: true },
      { key: 'agreements', label: 'AgreementsCount', visible: true },
      { key: 'contactThreadCartOfferMails', label: 'ShippedToCustomer', visible: true },
      // { key: 'lastMail', label: 'offer.MessageStatus', visible: true },
      // { key: 'nettPrice', label: 'offer.NettoPrice', visible: true },
      { key: 'priceGrossEnd', label: 'offer.GrossPrice', visible: true },
      { key: 'offerValidityDays', label: 'offer.ValidityOfferTerm', visible: true },
      { key: 'files', label: 'Files', visible: true },
      // { key: 'isPDF', label: 'PDF', visible: true },
      { key: 'isWWW', label: 'WWW', visible: true },
      { key: 'messages', label: 'Messages', visible: true },
      // { key: 'isAgreement', label: 'contact.agreement', visible: true },
      // { key: 'allowElectronicSignature', label: 'contact.electronicSignature', visible: true },
      // { key: 'documents', label: 'contact.documents', visible: true },
      { key: 'offerWebViews', label: 'contact.views', visible: true },
      { key: 'createdBy', label: 'contact.creator', visible: true },
      { key: 'action', label: 'Action', visible: true },
    ],
    offers: [],

    offerToExtend: null,
    agreementTypes: [],
  }),
  computed: {
    fields() {
      return this.offerFields.filter(field => field.visible)
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadOffers()
      },
    },
  },
  async mounted() {
    await this.loadAgreementTypes()
    await this.loadSettings()

    this.$nextTick(async () => {
      if (!this.checkRequiredModule('agreementModule')) {
        const index = this.offerFields.findIndex(item => item.key === 'agreements')
        if (index !== -1) this.$delete(this.offerFields, index)
      }

      if (!this.checkRequiredPermissions([this.$roles.CONTACT_THREAD_AGREEMENT_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF])) {
        const index = this.offerFields.findIndex(item => item.key === 'agreements')
        if (index !== -1) this.$delete(this.offerFields, index)
      }
    })

    this.$nextTick(() => {
      if (this.threadId) {
        this.offerFields.splice(2, 2)
      }
      this.loadOffers()
    })
  },
  methods: {
    priceGrossFormat,
    formatDate,
    getOfferStatus(offer) {
      if (offer.expiredAt?.date && offer.status === 'NEW') {
        const now = moment()
        const expired = moment(offer.expiredAt.date, 'YYYY-MM-DD HH:mm:ss')

        if (now.isAfter(expired)) return 'EXPIRED'
      }

      return offer.status
    },
    async loadSettings() {
      try {
        const resp = await this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)
        this.autentiEnable = resp?.data?.item?.autentiEnabled || false
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async openAgreementModal(offer) {
      const agreement = offer.contactThreadCartBox

      const threadId = agreement.contactThread.id
      const contactId = agreement.contactThread.contact.id

      const contact = await axiosIns.get(`1/contacts/${contactId}`, { params: { fields_load: this.$fields.SINGLE_CONTACT } })
      const thread = await axiosIns.get(`1/contacts/threads/${threadId}`, { params: { fields_load: this.$fields.CONTACT_SINGLE_THREAD } })

      agreement.contactThread = thread.data?.data?.item || {}
      agreement.contactThread.contact = contact.data?.data?.item || null

      this.offerAgreement = {
        ...agreement, offerId: offer.id, threadId, contactId,
      }
      this.agreement = { ...emptyAgreement }
    },
    // eslint-disable-next-line consistent-return
    async loadAgreementTypes() {
      if (!this.checkRequiredModule('agreementModule')) {
        this.agreementTypes = []
        return []
      }

      try {
        const resp = await axiosIns.get('1/settings/agreementTypes', { params: { fields_load: this.$fields.SETTINGS_AGREEMENTS_TYPES } })
        this.agreementTypes = resp.data.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    title,
    changeStatus(item, status, index) {
      this.$store.dispatch(`offer/${UPDATE_OFFER}`, { id: item.id, status })
        .then(() => {
          this.$set(this.offers[index], 'status', status)
          this.showToast('success', this.$i18n.t('OfferChangeStatus'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    resolveEmailStatusColor,
    closeModal(reloadItem) {
      if (reloadItem) this.getSingleOffer(this.offerAgreement.id)

      this.offerAgreement = null
    },
    offerTotalGrossPrice() {
      return 1
    },
    getLocation() {
      return window.location.port ? 'https://dev.saleswizardapp.com' : window.location.origin
    },
    getDeliveredMessagesCount(contactThreadCartOfferMails) {
      return (contactThreadCartOfferMails || []).filter(contactThreadMail => contactThreadMail.mail.status === 'DELIVERED' || contactThreadMail.mail.status === 'OPEN') ?? []
    },
    signAgreement(agreementItem, offerId) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
        .then(() => {
          const { allowElectronicSignature } = agreementItem

          const status = allowElectronicSignature ? 'SIGNED_MANUALLY' : 'ACCEPTED'

          const payload = {
            id: agreementItem.id,
            status,
          }

          payload.signedAt = ''
          payload.filesSigned = []
          payload.offerId = offerId

          this.offerAgreementSignManually = payload
        })
    },
    /* eslint-disable */
    async updateAgreement(payload, offerId, action = 'edit') {
      this.agreementLoading = true
      if (payload?.filesSigned?.length) {
        const filesPayload = { uploadedFiles: payload.filesSigned, type: 'building' }
        const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, filesPayload)

        payload.filesSigned = files || []
      }

      if (action == 'add') {
        payload.agreementTemplate = ''
        payload.allowElectronicSignature = false
        payload.anonymised = false
        payload.signerUser = ''
        payload.contactThreadCartDraftAgreementAssignedContacts = []
        payload.contactThreadCartBox = payload.id
        delete payload.id
      }

      delete payload.offerId

      if (!payload.electronicSignatureType) {
          payload.electronicSignatureType = null
      }

      try {
        if (action !== 'add') await axiosIns.patch('1/contacts/threads/agreements', payload)
        else await axiosIns.post(`1/contacts/${payload.contactId}/threads/${payload.threadId}/agreements`, payload)

        this.getSingleOffer(offerId)
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.offerAgreement = null
        this.agreement = { ...emptyAgreement }
        this.agreementLoading = false
        this.offerAgreementSignManually = null
      }
    },
    duplicateOffer(offer, index) {
      const productsToCopy = JSON.parse(JSON.stringify(offer.contactThreadCartBox.contactThreadCartBoxProducts.map(product => product?.shopProduct.id)))
      const productsToDelete = JSON.parse(JSON.stringify(this.$store.getters['cart/getCartProducts'].map(product => product.id)))

      this.updateCart(offer.contactThreadCartBox)
      if (productsToDelete.length) this.removeProductsFromCart(productsToDelete, productsToCopy, index, offer.id, offer.status)
      else this.addProductsToCart(productsToCopy, index, offer.id, offer.status)
    },
    updateCart(cart) {
      delete cart.id
      this.$store.dispatch(`cart/${UPDATE_CART}`, { cart, id: this.$store.state.cart.id })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    removeProductsFromCart(productsToDelete, productsToCopy, index, offerId, status) {
      this.$store.dispatch(`cart/${REMOVE_PRODUCT_FROM_CART}`, { productId: productsToDelete })
        .then(() => {
          this.addProductsToCart(productsToCopy, index, offerId, status)
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    // eslint-disable-next-line no-unused-vars
    addProductsToCart(productsToCopy, index, offerId, status) {
      this.$store.dispatch(`cart/${ADD_PRODUCT_TO_CART}`, productsToCopy)
        .then(() => {
          this.showToast('success', this.$i18n.t('CartDuplicated'))
          this.$router.push({ name: 'shop/checkout' })
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    finishOffer({ id }, index) {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.CancelOffer'))
        .then(() => {
          this.closeOffer(id, index)
        })
    },
    closeOffer(id, index) {
      this.$store.dispatch(`offer/${UPDATE_OFFER}`, { id, status: 'CANCELLED' })
        .then(() => {
          this.$set(this.offers[index], 'status', 'CANCELLED')
          this.showToast('success', this.$i18n.t('OfferChangeStatus'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    extendOffer({ id, contactThreadCartBox, offerValidityDays }, index) {
      this.offerToExtend = {
        id,
        name: contactThreadCartBox.name,
        offerValidityDays: offerValidityDays || 0,
        validityDaysMin: offerValidityDays || 0,
        index,
      }
    },
    saveExtendedOffer() {
      const { index } = this.offerToExtend

      const oldDate = moment(this.offers[index].createdAt.date)
      const nowDate = moment(new Date())

      const payload = {
        id: this.offerToExtend.id,
      }

      payload.offerValidityDays = this.offers[index].status === 'EXPIRED' || this.offers[index].expiredAt ? nowDate.diff(oldDate, 'days') : oldDate.days()
      payload.offerValidityDays += Number(this.extendValidityDays)

      this.extendValidityDays = 0

      this.$store.dispatch(`offer/${UPDATE_OFFER}`, payload)
        .then(() => {

          this.getSingleOffer(payload.id, index)
          this.showToast('success', this.$i18n.t('OfferWasUpdated'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    sendOfferToClient({ id }, index) {
      this.showAlert('info', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('ResendEmailMessage'))
        .then(() => {
          const payload = {
            id,
            sendEmail: true,
          }
          this.$store.dispatch(`offer/${UPDATE_OFFER}`, payload)
            .then(() => {
              this.getSingleOffer(id, index)
              this.showToast('success', this.$i18n.t('OfferWasSended'))
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        })
    },
    getSingleOffer(id, index = -1) {
      if (id) {
        this.$store.dispatch(`offer/${GET_OFFER}`, id)
          .then(res => {
            const offer = res.data.item
            offer.priceGrossEnd = offer.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceGrossWithAllDiscount * b.amount || 1), 0) || 0
            offer.price = offer.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceWithAllDiscount * b.amount || 1), 0) || 0
            if (index !== -1) {
              this.$set(this.offers, index, offer)
            } else {
              // eslint-disable-next-line no-underscore-dangle
              const _index = this.offers.findIndex(item => item.id === id)
              if (_index >= 0) this.$set(this.offers, _index, offer)
            }
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            this.loading = false
          })
      }
    },
    isSomeAgreementAccepted(agreements = []) {
      const acceptStatuses = [
        'ACCEPTED',
        'SIGNED',
        'SIGNED_MANUALLY',
        'SIGNED_ELECTRONICALLY',
      ]
      return agreements.some(agreement => acceptStatuses.includes(agreement.status))
    },
    async loadOffers() {
      this.loading = true
      this.$nextTick(async () => {
        const payload = {
          pagination: this.pagination,
          search: this.searchQuery,
          filters: this.filters,
          threadId: this.threadId,
            sorter: this.sorter,
        }
        // this.$store.dispatch(`offer/${GET_THREAD_STATISTICS}`, payload)
        //   .then(status => {
        //     this.$emit('status', status)
        //   })
        //   .catch(err => {
        //     this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        //     this.loading = false
        //   })

        const action = this.threadId ? GET_THREAD_OFFERS : GET_OFFERS

        this.$store.dispatch(`offer/${action}`, payload)
          .then(res => {
            this.offers = res.data.items.map(offer => {
              // eslint-disable-next-line
              offer.priceGrossEnd = offer.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceGrossWithAllDiscount * b.amount || 1), 0) || 0
              // eslint-disable-next-line
              offer.price = offer.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceWithAllDiscount * b.amount || 1), 0) || 0
              return offer
            })
            this.pagination.totalRecords = res.data.totalItemCount
            this.loading = false
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            this.loading = false
          })
      })
    },
    calculateNettoPrice(item) {
      if (item.contactThreadOfferProducts) {
        return item.contactThreadOfferProducts.reduce((a, b) => +a + +((b.price * b.priceMultiplier) * b.amount), 0)
      }
      return 0
    },
    calculateGrossEndPrice(item) {
      if (item.contactThreadOfferProducts) {
        return item.contactThreadOfferProducts.reduce((a, b) => +a + +((b.price * b.priceMultiplier) * b.amount), 0)
      }
      return 0
    },
    downloadPdf(fileToken, filename_) {
      this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken, filename: filename_ })
        .then(res => {
          const { data, filename } = res
          const a = document.createElement('a')
          a.href = `data:${data.type};base64,${data.file}`
          a.setAttribute('target', '_top')
          a.download = filename
          setTimeout(() => {
            a.click()
          }, 50)
        })
    },
  },
}
</script>

<style lang="scss">
.tablePopover { width: 100%; }
.tablePopover span:last-of-type { border-bottom: none !important }
.popover-lg {
  width: 200px;
}
//.offer-table {
//  .table {
//    min-height: 300px;
//  }
//}
</style>
