var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"newProjectForm",attrs:{"tag":"form"}},[_c('b-row',{staticStyle:{"row-gap":".5rem"}},[_c('validation-provider',{staticClass:"col-12 mb-n50",attrs:{"rules":"required|max-input","name":_vm.$t('Title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Title')}},[_c('b-form-input',{attrs:{"disabled":!_vm.isEdit,"placeholder":_vm.$t('Title'),"state":errors.length > 0 ? false:null},on:{"input":function (e) { return _vm.$emit('getTitle', e); }},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{staticClass:"col-12",attrs:{"name":_vm.$t('SelectContacts')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('sw-select',{staticClass:"mb-0",attrs:{"name":_vm.$t('SelectContacts'),"disabled":!_vm.isEdit}},[_c('v-select',{attrs:{"id":"offer-thread-1","options":_vm.contactThreadList,"label":"name","filterable":false,"selectable":function (thread) { return thread.offersTotal < _vm.system.contactThreadOffersLimit; },"state":errors.length > 0 ? false:null,"multiple":"","placeholder":_vm.$t('SelectContacts'),"disabled":!_vm.isEdit},on:{"search":_vm.loadThreads,"select":_vm.save},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}},{key:"option",fn:function(ref){
var name = ref.name;
var contact = ref.contact;
var offersTotal = ref.offersTotal;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":contact}}),_vm._v(" - "+_vm._s(name)+" "),(offersTotal >= _vm.system.contactThreadOffersLimit)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"ml-50",attrs:{"icon":"AlertCircleIcon","title":_vm.$tc('ThreadOfferCountIsToBig', _vm.system.contactThreadOffersLimit, { limit: _vm.system.contactThreadOffersLimit })}}):_vm._e()],1)]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var contact = ref.contact;
return [_c('div',{staticClass:"d-flex"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":contact,"text-variant":"text-white"}}),_vm._v(" – "+_vm._s(name)+" ")],1)]}}],null,true),model:{value:(_vm.contactThreads),callback:function ($$v) {_vm.contactThreads=$$v},expression:"contactThreads"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{staticClass:"col-12 mt-n50",attrs:{"name":_vm.$t('SelectUsers')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('sw-select',{staticClass:"mb-0",attrs:{"name":_vm.$t('SelectUsers'),"disabled":!_vm.isEdit}},[_c('v-select',{attrs:{"id":"offer-thread-1","options":_vm.userList,"label":"firstName","filterable":false,"state":errors.length > 0 ? false:null,"multiple":"","placeholder":_vm.$t('SelectUsers'),"disabled":!_vm.isEdit},on:{"search":_vm.loadUsers,"select":_vm.save},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}},{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var avatar = ref.avatar;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":{ firstName: firstName, lastName: lastName, avatar: avatar }}})],1)]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var avatar = ref.avatar;
return [_c('div',{staticClass:"d-flex"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":{ firstName: firstName, lastName: lastName, avatar: avatar },"text-variant":"text-white"}})],1)]}}],null,true),model:{value:(_vm.assignedUsers),callback:function ($$v) {_vm.assignedUsers=$$v},expression:"assignedUsers"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('div',{staticClass:"col-12 mt-n50"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"variant":"primary","disabled":!_vm.isEdit},model:{value:(_vm.isWholeDay),callback:function ($$v) {_vm.isWholeDay=$$v},expression:"isWholeDay"}},[_vm._v(" "+_vm._s(_vm.$t('WholeDay'))+" ")]),_c('b-row',{staticClass:"mt-50"},[_c('b-col',{attrs:{"md":_vm.isWholeDay ? 12 : 6}},[_c('sw-select',{attrs:{"name":_vm.isWholeDay ? _vm.$t('ProjectTerm') : _vm.$t('ProjectStartTerm')}},[_c('sw-select-fpr',{attrs:{"is-empty":_vm.startAt},on:{"clear":function () {
                _vm.startAt = ''

                if (_vm.isWholeDay) { _vm.endAt = '' }
              }}},[_c('flat-pickr',{key:("time_" + _vm.isWholeDay),staticClass:"form-control",attrs:{"disabled":!_vm.isEdit,"placeholder":_vm.isWholeDay ? _vm.$t('ProjectTerm') : _vm.$t('ProjectStartTerm'),"config":Object.assign({}, _vm.flatPickerConfig, {enableTime: true, locale: _vm.getCalendarLocale(_vm.$i18n.locale), maxDate: _vm.endAt && !_vm.isWholeDay ? _vm.endAt : '', dateFormat: 'Y-m-d H:i'})},model:{value:(_vm.startAt),callback:function ($$v) {_vm.startAt=$$v},expression:"startAt"}})],1)],1)],1),(!_vm.isWholeDay)?_c('b-col',{attrs:{"md":"6"}},[_c('sw-select',{attrs:{"name":_vm.isWholeDay ? _vm.$t('ProjectTerm') : _vm.$t('ProjectEndTerm')}},[_c('sw-select-fpr',{attrs:{"is-empty":_vm.endAt},on:{"clear":function($event){_vm.endAt = ''}}},[_c('flat-pickr',{key:("time_end_" + _vm.isWholeDay),staticClass:"form-control",attrs:{"disabled":!_vm.isEdit,"placeholder":_vm.isWholeDay ? _vm.$t('ProjectTerm') : _vm.$t('ProjectEndTerm'),"config":Object.assign({}, _vm.flatPickerConfig, {enableTime: !_vm.isWholeDay, locale: _vm.getCalendarLocale(_vm.$i18n.locale), dateFormat: _vm.isWholeDay ? 'Y-m-d' : 'Y-m-d H:i', minDate: _vm.startAt ? _vm.startAt : ''})},model:{value:(_vm.endAt),callback:function ($$v) {_vm.endAt=$$v},expression:"endAt"}})],1)],1)],1):_vm._e()],1)],1),_c('b-form-group',{staticClass:"col-12",attrs:{"label":_vm.$t('note.Description')}},[_c('b-form-textarea',{staticStyle:{"height":"30rem"},attrs:{"disabled":!_vm.isEdit,"placeholder":_vm.$t('note.Description')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('b-overlay',{attrs:{"no-wrap":"","show":_vm.isLoading || _vm.isMeLoading,"spinner-variant":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }