<template>
  <div>
    <!--    START::Settlement    -->
    <settlement
      v-if="checkRequiredPermissions([$roles.MARKETING_PLAN_POINT_MODIFY, $roles.MARKETING_PLAN_POINT_SETTLEMENT]) && status === 'NEW'"
      :disabled="!selectedPayments.length || toSettlement < 0"
      class="mb-2"
      :is-loading="loading"
      :val="toSettlement"
      :val-gross="toSettlementGross"
      @openModal="isOpenSettle = true"
    />

    <b-alert
      v-if="status === 'TO_PAY'"
      show
      variant="success"
      class="p-2 d-flex flex-wrap align-items-center justify-content-between"
      style="row-gap: .5rem"
    >
      <div class="d-flex align-items-center">
        <b-avatar variant="success">
          <feather-icon icon="DollarSignIcon" />
        </b-avatar>

        <div class="d-flex flex-column ml-75">
          <strong>
            {{ $t('PaymentStatuses.TO_PAY') }}
          </strong>

          <div>
            <span>
              {{ $t('NetAmount') }}:
              <strong>{{ toSettlement | priceFormat }}</strong>
              PLN |
            </span>
            <span>
              {{ $t('Gross') }}:
              <strong>{{ toSettlementGross | priceFormat }}</strong>
              PLN |
            </span>
            <span>
              {{ $t('VAT') }}:
              <strong>{{ toSettlementGross - toSettlement | priceFormat }}</strong>
              PLN
            </span>
          </div>
        </div>

        <b-overlay
          :show="loading"
          no-wrap
          spinner-variant="success"
        />
      </div>

      <div>
        <b-button
          :disabled="!selectedPayments.length"
          variant="success"
          @click="completeSelectedPayments()"
        >
          {{ $t('PayOut') }}
        </b-button>
      </div>
    </b-alert>

    <div class="my-50">

      <!--    START::Add Button    -->
      <b-button
        v-if="checkRequiredPermissions([$roles.MARKETING_PLAN_POINT_MODIFY])"
        variant="primary"
        size="sm"
        class="mr-50"
        @click="isOpenBalanceChange = true"
      >
        <feather-icon icon="PlusIcon"
                      class="mr-25"
        />
        {{ $t('Add') }}
      </b-button>

      <b-button
        v-if="checkRequiredPermissions([$roles.FULL_ACCESS]) || checkRequiredViewPermissions([$viewRanks.SHOW_MARKETING_POINT_PAYMENTS_EXPORT])"
        variant="primary"
        size="sm"
        :disabled="exportLoading"
        @click="getRecordsToExport"
      >
        <span v-if="!exportLoading"><feather-icon icon="DownloadIcon"
                                                  class="mr-25"
        />Export</span>
        <span v-else>
          <b-spinner
            small
            variant="light"
          />
        </span>
      </b-button>
      <!--    END::Add Button    -->
    </div>

    <!--    END::Settlement    -->
    <b-card>

      <!-- eslint-disable -->
      <sw-table
        table-id="aba02471780c4ee384e1a3e42a287e8b"
        show-search
        show-columns
        :fields.async="fields"
        :pagination="pagination"
        @set-fields="fields = $event"
        @change-pagination="Object.assign(pagination, $event)"
        @change-search-query="search = $event"
        @reload-content="loadData"
      >
        <template #table>
          <b-table
            ref="selectableTable"
            :items="commissions"
            class="table-wrap-words mx-0 px-0"
            :fields="visibleFields.filter(e => e.visible)"
            striped
            responsive
            :busy="loading"
            show-empty
          >
            <template #head()="{ label }">
              <template>
                {{ $t(`${label}`) }}
              </template>
            </template>

            <template #head(select)>
              #
              <!--              <b-form-checkbox-->
              <!--                v-model="checkedAll"-->
              <!--                @change="toggleChecked"-->
              <!--              />-->
            </template>

            <template #cell(select)="{ item }">
              <b-form-checkbox
                variant="primary"
                v-model="selectedPayments"
                :disabled="!allowSelect(item)"
                :value="item.id"
              />
            </template>

            <template #cell(isDone)="{ value }">
              <template v-if="!value && value !== false">
                <feather-icon
                  size="18"
                  icon="SquareIcon"
                  class="text-secondary"
                />
              </template>

              <template v-else-if="value === true">
                <feather-icon
                  size="18"
                  icon="CheckSquareIcon"
                  class="text-success"
                />
              </template>

              <template v-else>
                <feather-icon
                  size="18"
                  icon="MinusSquareIcon"
                  class="text-danger"
                />
              </template>
            </template>

            <template #cell(description)="{ value }">
              <div
                v-b-tooltip
                :title="value"
              >
                {{ value | truncate(40) }}
              </div>
            </template>
            <template #cell(type)="{ value }">
              <div v-if="value">
                {{ $t(value) }}
              </div>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(user)="{ item }">
              <avatar
                v-if="item.marketingPlanPoint && item.marketingPlanPoint && item.marketingPlanPoint.user"
                :user="item.marketingPlanPoint.user"
              />
              <avatar
                v-else-if="item.marketingPlanPointUser"
                :user="item.marketingPlanPointUser"
              />
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(seller)="{ item }">
              <avatar
                v-if="item.marketingPlanPoint && item.marketingPlanPoint.contactThreadCartBox && item.marketingPlanPoint.contactThreadCartBox.contactThreadCartOrder && item.marketingPlanPoint.contactThreadCartBox.contactThreadCartOrder.seller"
                :user="item.marketingPlanPoint.contactThreadCartBox.contactThreadCartOrder.seller"
              />
              <avatar
                v-else-if="item.contactThreadCartOrder"
                :user="item.contactThreadCartOrder.seller"
              />
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(contact)="{ item }">
              <div v-if="item.contactThread && item.contactThread.contact">
                <avatar :user="item.contactThread.contact"
                        :truncate="40"
                />
              </div>
              <div v-else-if="item.marketingPlanPoint && item.marketingPlanPoint.contactThreadCartBox && item.marketingPlanPoint.contactThreadCartBox.contactThread && item.marketingPlanPoint.contactThreadCartBox.contactThread.contact">
                <avatar :user="item.marketingPlanPoint.contactThreadCartBox.contactThread.contact"
                        :truncate="40"
                />
              </div>
              <div v-else-if="item.contactThreadCartOrder && item.contactThreadCartOrder.contactThreadCartBox && item.contactThreadCartOrder.contactThreadCartBox.contactThread && item.contactThreadCartOrder.contactThreadCartBox.contactThread.contact">
                <avatar :user="item.contactThreadCartOrder.contactThreadCartBox.contactThread.contact"
                        :truncate="40"
                />
              </div>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(thread)="{ item }">
              <div v-if="item.contactThread">
                {{ item.contactThread.name }}
              </div>
              <div v-else-if="item.marketingPlanPoint && item.marketingPlanPoint.contactThreadCartBox.contactThread">
                {{ item.marketingPlanPoint.contactThreadCartBox.contactThread.name }}
              </div>
              <div v-else-if="item.contactThreadCartOrder && item.contactThreadCartOrder.contactThreadCartBox.contactThread">
                {{ item.contactThreadCartOrder.contactThreadCartBox.contactThread.name }}
              </div>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(products)="{ item }">
              <b-badge
                variant="primary"
                class="text-nowrap"
              >
                {{ `${$t('Products')}: ${item.productsCount}` }}
              </b-badge>
            </template>

            <template #cell(nettoPrice)="{ item }">
              <span
                v-if="item.marketingPlanPoint"
                class="text-nowrap"
              >
                {{ item.marketingPlanPoint.contactThreadCartBox.totalPriceWithDiscount | priceFormat }}
                PLN
              </span>
              <span
                v-else-if="item.contactThreadCartOrder"
                class="text-nowrap"
              >
                {{ item.contactThreadCartOrder.contactThreadCartBox.totalPriceWithDiscount | priceFormat }}
                PLN
              </span>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(grossPrice)="{ item }">
              <span
                v-if="item.marketingPlanPoint"
                class="text-nowrap"
              >
                {{ item.marketingPlanPoint.contactThreadCartBox.totalGrossPriceWithDiscount | priceGrossFormat }}
                PLN
              </span>
              <span
                v-else-if="item.contactThreadCartOrder"
                class="text-nowrap"
              >
                {{ item.contactThreadCartOrder.contactThreadCartBox.totalGrossPriceWithDiscount | priceGrossFormat }}
                PLN
              </span>
              <span
                v-else
                class="text-nowrap"
              >
                {{ 0 | priceFormat }}
                PLN
              </span>
            </template>

            <template #cell(statusOfOrder)="{ value }">
              <b-badge
                variant="light-primary"
                class="text-nowrap"
              >
                {{ $t(value) }}
              </b-badge>
            </template>

            <template #cell(paymentMethod)="{ item }">
              <b-badge
                :variant="item.marketingPlanPoint && item.marketingPlanPoint.contactThreadCartBox ? paymentTypeColors[item.marketingPlanPoint.contactThreadCartBox.paymentType] : (item.contactThreadCartOrder && item.contactThreadCartOrder.contactThreadCartBox ? paymentTypeColors[item.contactThreadCartOrder.contactThreadCartBox.paymentType] : '')"
                class="text-nowrap"
              >
                <span v-if="item.marketingPlanPoint">
                  {{ $t(paymentTypes[item.marketingPlanPoint.contactThreadCartBox.paymentType]) }}
                </span>
                <span v-else-if="item.contactThreadCartOrder">
                  {{ $t(paymentTypes[item.contactThreadCartOrder.contactThreadCartBox.paymentType]) }}
                </span>
                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
              </b-badge>
            </template>

            <template #cell(id)="{ index }">
              #{{ getOrderNo(index, pagination, sorter) }}
            </template>

            <template #cell(commissionType)="{ value }">
              <b-badge
                variant="light-primary"
                class="text-nowrap"
              >
                {{ $t(value) }}
              </b-badge>
            </template>

            <template #cell(grossCommissionAmount)="{ value }">
              <span class="d-flex align-items-center">
                <span class="text-nowrap">
                  {{ value.val | priceGrossFormat }}
                  PLN
                </span>

                <b-button
                  v-b-tooltip.hover.v-primary
                  :variant="value.isQualified ? 'flat-success' : 'flat-warning'"
                  size="sm"
                  class="btn-icon ml-25 p-25"
                  :title="$t(value.isQualified ? 'QualifiedCommission' : 'NotionalCommission')"
                >
                  <feather-icon :icon="value.isQualified ? 'CheckCircleIcon' : 'AlertCircleIcon'" />
                </b-button>
              </span>
            </template>

            <template #cell(toPaid)="{ value }">
              <span class="text-nowrap">
                {{ value | priceFormat }}
                PLN
              </span>
            </template>

            <template #cell(paid)="{ item }">
              <span
                v-if="item.marketingPlanPoint"
                class="text-nowrap"
              >
                {{ (item.pointCollectsSum || 0) | priceFormat }}
                PLN / {{ (item.marketingPlanPoint.value) | priceFormat }} PLN
              </span>
              <span v-else>
                {{ (item.toPaid || 0) | priceFormat }} PLN
              </span>
            </template>

            <template #cell(status)="{ value }">
              <b-badge variant="light-primary">
                {{ $t(`PaymentStatuses.${value}`) }}
              </b-badge>
            </template>

            <template #cell(orderID)="{ item }">
              <b-badge
                v-if="item.marketingPlanPoint"
                variant="light-primary"
              >
                {{ item.marketingPlanPoint.contactThreadCartBox.contactThreadCartOrder.number }}
              </b-badge>
              <b-badge
                v-else-if="item.contactThreadCartOrder"
                variant="light-primary"
              >
                {{ item.contactThreadCartOrder.number }}
              </b-badge>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>
            <template #cell(orderDate)="{ item }">
              <b-badge
                v-if="item.marketingPlanPoint && item.marketingPlanPoint.contactThreadCartBox && item.marketingPlanPoint.contactThreadCartBox.contactThreadCartOrder && item.marketingPlanPoint.contactThreadCartBox.contactThreadCartOrder.orderDate"
                variant="light-primary"
              >
                {{ formatDate(item.marketingPlanPoint.contactThreadCartBox.contactThreadCartOrder.orderDate.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, $i18n.locale) }}
              </b-badge>
              <b-badge
                v-else-if="item.contactThreadCartOrder && item.contactThreadCartOrder.orderDate"
                variant="light-primary"
              >
                {{ formatDate(item.contactThreadCartOrder.orderDate.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, $i18n.locale) }}
              </b-badge>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(documents)="{ value }">
              <feather-icon
                v-for="(doc, i) in value"
                :key="i"
                v-b-tooltip.hover
                icon="FileTextIcon"
                size="23"
                :title="doc.name"
              />
            </template>

            <template #cell(files)="{ value }">
              <documents-list
                v-if="Array.isArray(value) && value.length"
                :documents="value"
              />
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <!--     Action     -->
            <template #cell(action)="{ item }">
              <b-dropdown
                id="dropdown-dropleft"
                dropleft
                no-caret
                variant="flat"
              >
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" />
                </template>

                <b-dropdown-item @click="completePayment(item.id, item)">
                  {{ $t('PayOut') }}
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <!-- Other -->
            <!--    Other    -->
            <template #cell()="row">
              <table-default-cell
                :field="row.field"
                :value="row.value"
              />
            </template>

            <!--      Empty      -->
            <template #empty>
              <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
        </template>
      </sw-table>

    </b-card>
    <!--    START::Modal Settle    -->
    <b-modal
      v-model="isOpenSettle"
      :title="$t('Settle')"
      hide-footer
      tabindex="none"
      size="md"
    >
      <div>
        <div class="d-flex align-items-end">
          <b-avatar
            variant="light-primary"
            class="mr-75"
          >
            <feather-icon
              icon="BoxIcon"
              size="16"
            />
          </b-avatar>

          <span class="d-flex flex-column mt-50">
            <strong>
              {{ $t('YourPayoutCommission') }}
            </strong>

            <span class="text-primary">
              {{ toSettlement | priceFormat }}
              PLN
              {{ $t('Net') }}
            </span>
          </span>
        </div>
        <h5 class="mt-50">
          {{ $t('AddAccountingDocument') }}
        </h5>
        <sw-select
          class="mt-75"
          :name="$t('Date')"
        >
          <sw-select-fpr>
            <flat-pickr
              ref="timeRange"
              v-model="accountedAt"
              class="form-control"
              :config="{ dateFormat: 'd-m-Y', locale: getCalendarLocale($i18n.locale) }"
            />
          </sw-select-fpr>
        </sw-select>

        <b-form-group :label="$t('Description')">
          <b-form-textarea
            id="note"
            v-model="note"
            rows="3"
          />
        </b-form-group>

        <b-form-group :label="$t('DocumentNumber')">
          <b-form-input
            id="document-number"
            v-model="accountFileNumber"
          />
        </b-form-group>

        <drag-drop-upload
          :disabled="loading"
          multiple
          @onChangeFiles="changeFiles"
        />

        <b-form-checkbox
          v-model="setAsPaid"
          variant="primary"
          class="custom-control-primary"
        >
          {{ $t('SetAsPaid') }}
        </b-form-checkbox>
      </div>

      <b-button
        :disabled="!accountedAt"
        variant="success"
        class="mt-2"
        @click="accountPayments"
      >
        {{ $t('Add') }}
      </b-button>
    </b-modal>
    <!--    END::Modal Settle    -->

    <!--    &lt;!&ndash;    START::Modal Balance Change    &ndash;&gt;-->
    <b-modal
      v-model="isOpenBalanceChange"
      :title="$t('ManualBalanceChange')"
      hide-footer
      tabindex="none"
      size="md"
    >
      <modal-balance-change
        :contact-thread="contactThread"
        @reload="isOpenBalanceChange = false; loadData()"
      />
    </b-modal>
    <!--    &lt;!&ndash;    END::Modal Balance Change    &ndash;&gt;-->
  </div>
</template>

<script>
import { BFormTextarea, BTable, VBTooltip } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import { formatDate } from '@core/utils/filter'
import Settlement from '@/pages/commissions/components/withdrawals/Settlement.vue'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import { UPLOAD_FILES } from '@/@constants/mutations'
import flatPickr from 'vue-flatpickr-component'
import DocumentsList from '@/views/components/DocumentsList.vue'
import ModalBalanceChange from '@/pages/commissions/components/withdrawals/ModalBalanceChange.vue'
import moment from 'moment'
import XLSX from 'xlsx'

export default {
  components: {
    ModalBalanceChange,
    BTable,
    Settlement,
    DragDropUpload,
    flatPickr,
    BFormTextarea,
    DocumentsList,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    status: {
      default: 'TO_PAY',
      required: true,
      type: String,
    },
    filters: {
      default: null,
      type: Object,
    },
    contactThread: {
      default: null,
      required: false,
    },
  },
  data: vm => ({
    isOpenSettle: false,
    exportLoading: false,
    loading: false,
    paymentTypeColors: {
      ADVANCE: 'info',
      TOTAL_PAYMENT: 'light-info',
      BANK_INSTALLMENT: 'warning',
      INSTALLMENT: 'light-warning',
      TRANSFER: 'primary',
      CASH: 'light-primary',
      LOYALTY_POINTS: 'light-success',
    },
    isOpenBalanceChange: false,
    commissions: [],
    fields: [
      { key: 'select', label: 'Select', visible: true },
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      {
        visible: true, key: 'type', label: 'task.Type', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_TYPE],
      },
      {
        visible: true, key: 'orderID', label: 'OrderID', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_NUMBER],
      },
      {
        visible: true, key: 'orderDate', label: 'OrderDate', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_ORDER_DATE],
      },
      {
        visible: true, key: 'contact', label: 'Contact/Client', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_CONTACT],
      },
      {
        visible: true, key: 'thread', label: 'Thread', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_THREAD],
      },
      {
        visible: true, key: 'products', label: 'Products', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_PRODUCTS],
      },
      {
        visible: true, key: 'nettoPrice', label: 'offer.NettoPrice', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_NETTO_PRICE],
      },
      {
        visible: true, key: 'grossPrice', label: 'offer.GrossPrice', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_GROSS_PRICE],
      },
      {
        visible: true, key: 'paymentMethod', label: 'PaymentMethod', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_PAYMENT_METHOD],
      },
      {
        visible: true, key: 'toPaid', label: 'ToPay', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_TO_PAY],
      },
      {
        visible: true, key: 'paid', label: 'PaidOut', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_PAID_OUT],
      },
      {
        visible: true, key: 'seller', label: 'Seller', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_SELLER],
      },
      {
        visible: true, key: 'user', label: 'CommissionFor', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_COMMISSION_FOR],
      },
      {
        visible: true, key: 'status', label: 'CommissionPaymentStatuses', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_COMMISSION_STATUS],
      },
      {
        visible: true, key: 'description', label: 'Description', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_DESCRIPTION],
      },
      {
        visible: true, key: 'accountFileNumber', label: 'DocumentNumber', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_DOCUMENT_NUMBER],
      },
      { visible: true, key: 'accountedAt', label: 'DocumentDate' },
      {
        visible: true, key: 'files', label: 'Documents', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_DOCUMENT],
      },
      {
        visible: true, key: 'action', label: 'Action', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_MARKETING_COMMISSION_PAYMENTS_COLUMN_ACTION],
      },
    ],
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 50,
      currentPage: 1,
      totalRecords: 100,
    },
    search: '',
    paymentTypes: {
      ADVANCE: 'AdvancePayment',
      INSTALLMENT: 'Installment',
      TRANSFER: 'Przelew',
      CASH: 'Cash',
      LOYALTY_POINTS: 'LoyaltyPoints',
      TOTAL_PAYMENT: 'TotalAmount',
    },

    checkedAll: false,
    selectedPayments: [],
    paymentsUser: null,
    accountFileNumber: '',
    accountFiles: [],
    note: '',
    setAsPaid: false,
    accountedAt: new Date(),
  }),
  computed: {
    toSettlement() {
      return this.commissions.filter(a => this.selectedPayments.includes(a.id)).reduce((a, b) => (+a + +b.toPaid), 0)
    },
    toSettlementGross() {
      return this.commissions.filter(a => this.selectedPayments.includes(a.id)).reduce((a, b) => (+a + this.getUserGrossPrice(b)), 0)
    },
    visibleFields() {
      if (this.status === 'NEW') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.fields.slice(0, -1).filter(e => !e?.requiredViewRanks || this.checkRequiredViewPermissions(e.requiredViewRanks))
      }

      if (this.status === 'TO_PAY') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.fields.slice(0).filter(e => !e?.requiredViewRanks || this.checkRequiredViewPermissions(e.requiredViewRanks))
      }

      return this.fields.slice(1, -1).filter(e => !e?.requiredViewRanks || this.checkRequiredViewPermissions(e.requiredViewRanks))
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        if (!this.loading) {
          this.loadData()
        }
      },
    },
    // selectedPayments(newValue) {
    // if (newValue.length === 1) {
    // const id = newValue[0]
    // const index = this.commissions.findIndex(commission => commission.id === id)
    // if (index > -1) this.paymentsUser = this.commissions[index].marketingPlanPoint.userMarketingPlan.user
    // }

    // if (newValue.length === 0) {
    //   this.paymentsUser = null
    // }
    // },
    status(newValue, oldValue) {
      if (oldValue && newValue && oldValue !== newValue) {
        this.loadData()
      }
    },
  },
  async mounted() {
    this.paymentsUser = this.$store.getters['auth/getCurrentUser'].id
    await this.loadData()
  },
  methods: {
    formatDate,
    getSelectUserId(collect) {
      if (collect.marketingPlanPoint?.user) return collect.marketingPlanPoint.user.id
      if (collect.marketingPlanPointUser) return collect.marketingPlanPointUser.id

      return 0
    },

    getUserGrossPrice(payment) {
      const price = payment.toPaid
      let user = null
      if (payment.marketingPlanPoint?.user) user = payment.marketingPlanPoint.user
      else if (payment.marketingPlanPointUser) user = payment.marketingPlanPointUser

      if (!user.isOrganization) return price

      const vat = user.organization.vat || 0
      if (!vat) return price

      return price * ((vat / 100) + 1)
    },
    changeFiles(files) {
      this.$set(this, 'accountFiles', files)
    },
    toggleChecked(value) {
      if (value) this.selectedPayments = this.commissions.map(item => item.id)
      else this.selectedPayments = []
    },
    allowSelect(item) {
      if (this.checkRequiredPermissions([this.$roles.FULL_ACCESS, this.$roles.MARKETING_PLAN_POINT_SETTLEMENT])) return true
      if (this.checkRequiredPermissions([this.$roles.MARKETING_PLAN_POINT_MODIFY])) {
        if (item.toPaid < 0) return false
        if (this.paymentsUser === this.getSelectUserId(item)) {
          return true
        }
      }

      return false
      // !checkRequiredPermissions([$roles.MARKETING_PLAN_POINT_MODIFY, $roles.MARKETING_PLAN_POINT_SETTLEMENT])
      // || (checkRequiredPermissions([$roles.FULL_ACCESS]) && (paymentsUser !== getSelectUserId(item) || item.toPaid < 0))"
    },
    // eslint-disable-next-line consistent-return
    async loadData(pagination = false) {
      if (!pagination) {
        this.$emit('load-stats', this.search)
        this.$emit('close-modal')
        this.loading = true
      }

      try {
        const params = {
          fields_load: this.$fields.MARKETING_PLAN_POINT_PAYMENTS,
          orderBy: 'id.desc',
        }

        if (pagination) {
          params.page = pagination.currentPage
          params.limit = pagination.perPage
        } else if (this.pagination) {
          params.page = this.pagination.currentPage
          params.limit = this.pagination.perPage
        }

        if (this.search && this.search.length) {
          const searchString = this.search.replaceAll(' ', '%')
          params.search = `%${searchString}%`
          params.search_fields = 'accountFileNumber,marketingPlanPoint-contactThreadCartBox-contactThreadCartOrder-number,contactThreadCartOrder-number,marketingPlanPointUser-firstName,marketingPlanPointUser-lastName'
        }
        // ,marketingPlanPoint-userMarketingPlan-user-firstName,marketingPlanPoint-userMarketingPlan-user-lastName
        if (this.filters?.seller?.length) params['eqArr_marketingPlanPoint-contactThreadCartBox-contactThreadCartOrder-seller-id'] = JSON.stringify(this.filters.seller.mapKeys())
        if (this.filters?.type) params.eq_type = this.filters?.type
        if (this.filters?.provisionFor?.length) params['eqArr_marketingPlanPointUser-id'] = JSON.stringify(this.filters.provisionFor.mapKeys())
        if (this.filters?.agreementDate) {
          const [startAt, endAt] = this.filters.agreementDate.dateRange()
          if (startAt) params['gte_marketingPlanPoint-contactThreadCartBox-contactThreadCartOrder-contactThreadCartBox-contactThreadAgreements-signedAt'] = `${moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 00:00:00`
          if (endAt) params['lt_marketingPlanPoint-contactThreadCartBox-contactThreadCartOrder-contactThreadCartBox-contactThreadAgreements-signedAt'] = `${moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 23:59:59`
        }

        if (this.filters?.orderDate) {
          const [startAt, endAt] = this.filters.orderDate.dateRange()
          if (startAt) params['gte_contactThreadCartOrder-orderDate'] = `${moment(startAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 00:00:00`
          if (endAt) params['lt_contactThreadCartOrder-orderDate'] = `${moment(endAt, 'YYYY-MM-DD').format('YYYY-MM-DD')} 23:59:59`
        }

        if (this.status && !this.contactThread) {
          params.eq_status = this.status
        }

        if (this.contactThread) {
          params['eq_1_contactThread-id'] = this.getObjectId(this.contactThread)
          params.eq_1_status = this.status

          params['eq_2_marketingPlanPoint-contactThreadCartBox-contactThread-id'] = this.getObjectId(this.contactThread)
          params.eq_2_status = this.status

          params['eq_3_contactThreadCartOrder-contactThreadCartBox-contactThread-id'] = this.getObjectId(this.contactThread)
          params.eq_3_status = this.status
        }

        const resp = await axiosIns.get('1/marketingPlans/pointPayments/collects', { params })

        if (!pagination) {
          this.commissions = resp.data?.data?.items ?? []
          this.selectedPayments = this.commissions.filter(c => c.toPaid < 0
                && (this.paymentsUser.id === c.marketingPlanPointUser?.id || this.paymentsUser.id === c.marketingPlanPoint?.user?.id)).map(c => c.id)
          this.pagination.totalRecords = resp.data.data.totalItemCount ?? 0
        }

        return resp.data
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    async accountPayments() {
      const ids = this.selectedPayments
      if (!ids.length) return

      if (this.accountFiles.length) {
        const payload = { uploadedFiles: this.accountFiles, type: 'building' }
        const resp = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, payload)

        await this.accountPaymentsProcess(resp.files)
      } else {
        await this.accountPaymentsProcess([])
      }
    },
    async accountPaymentsProcess(files) {
      try {
        const payload = this.selectedPayments.map(id => ({
          id,
          status: this.setAsPaid ? 'PAID' : 'TO_PAY',
          files,
          accountedAt: this.accountedAt,
          accountFileNumber: this.accountFileNumber,
          description: this.note,
        }))

        await axiosIns.patch('1/marketingPlans/pointPayments/collects', payload)

        this.accountFiles = []
        this.accountFileNumber = ''
        this.note = ''
        this.accountedAt = new Date()
        this.setAsPaid = false
        this.isOpenSettle = false
        // this.paymentsUser = null
        this.selectedPayments = []
        this.$emit('load-stats')
        await this.loadData()
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async completeSelectedPayments() {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
        .then(async () => {
          try {
            const payload = []

            this.selectedPayments.forEach(e => {
              payload.push({
                id: this.getObjectId(e),
                status: 'PAID',
              })
            })

            await axiosIns.patch('1/marketingPlans/pointPayments/collects', payload)

            this.$emit('load-stats')
            await this.loadData()
            this.showToast('success', this.$i18n.t('success.contactUpdated'))
          } catch (err) {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          }
        })
    },
    async completePayment(id, item) {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
        .then(async () => {
          try {
            const payload = []
            const otherForDocument = this.commissions.filter(e => e.accountFileNumber === item.accountFileNumber)

            otherForDocument.forEach(e => {
              payload.push({
                id: e.id,
                status: 'PAID',
              })
            })

            await axiosIns.patch('1/marketingPlans/pointPayments/collects', payload)

            this.$emit('load-stats')
            await this.loadData()
            this.showToast('success', this.$i18n.t('success.contactUpdated'))
          } catch (err) {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          }
        })
    },
    async getRecordsToExport() {
      this.exportLoading = true
      try {
        const chunksToLoad = Math.ceil(this.pagination.totalRecords / this.pagination.perPage)
        const requests = new Array(chunksToLoad).fill(null)
        const response = await Promise.all(requests.map((_, index) => this.loadData({ ...this.pagination, currentPage: index + 1 })))

        const items = []
        response.forEach(res => {
          items.push(...res.data?.items.map(order => {
            if (order.contactThreadCartOrder) {
              return {
                ...order.contactThreadCartOrder,
                ...order,
                orderStatus: order?.contactThreadCartOrder?.status,
                nettoPrice: this.$options.filters.priceFormat(order.contactThreadCartOrder?.totalPriceWithDiscount),
                grossPrice: this.$options.filters.priceGrossFormat(order.contactThreadCartOrder?.totalGrossPriceWithDiscount),
                contactThread: { ...(order.contactThreadCartOrder?.contactThreadCartBox?.contactThread || {}) },
              }
            }
            return {
              ...order,
            }
          }))
        })

        this.$nextTick(() => {
          this.exportRecords(items)
        })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    /* eslint-disable */
      async exportRecords(recordsList) {
          const wb = XLSX.utils.book_new()

          const order = [
              { key: 'type', label: 'task.Type' },
              { key: 'number', label: 'OrderID' },
              { key: 'createdAt', label: 'CreatedAt' },
              { key: 'orderDate', label: 'OrderDate' },
              { key: 'contact', label: 'Contact/Client' },
              { key: 'thread', label: 'Thread' },
              { key: 'nettoPrice', label: 'offer.NettoPrice' },
              { key: 'grossPrice', label: 'offer.GrossPrice' },
              { key: 'paymentMethod', label: 'PaymentMethod' },
              { key: 'toPaid', label: 'ToPay' },
              { key: 'paid', label: 'PaidOut' },
              { key: 'seller', label: 'Seller' },
              { key: 'user', label: 'CommissionFor' },
              { key: 'status', label: 'CommissionPaymentStatuses' },
              { key: 'description', label: 'Description' },
              { key: 'accountFileNumber', label: 'DocumentNumber' },
          ]

          const aoaToExport = [
              ...recordsList.map(t => order.map(({ key }) => {
                  if (key === 'grossPrice') {
                      if (t?.marketingPlanPoint?.contactThreadCartBox?.totalGrossPriceWithDiscount) {
                          return this.$options.filters.priceGrossFormat(t.marketingPlanPoint.contactThreadCartBox.totalGrossPriceWithDiscount)
                      } else if (t?.contactThreadCartOrder?.contactThreadCartBox?.totalGrossPriceWithDiscount) {
                          return this.$options.filters.priceGrossFormat(t.contactThreadCartOrder.contactThreadCartBox.totalGrossPriceWithDiscount)
                      }
                  }
                  if (key === 'nettoPrice') {
                      if (t?.marketingPlanPoint?.contactThreadCartBox?.totalPriceWithDiscount) {
                          return this.$options.filters.priceFormat(t.marketingPlanPoint.contactThreadCartBox.totalPriceWithDiscount)
                      } else if (t?.contactThreadCartOrder?.contactThreadCartBox?.totalPriceWithDiscount) {
                          return this.$options.filters.priceFormat(t.contactThreadCartOrder.contactThreadCartBox.totalPriceWithDiscount)
                      }
                  }
                  if (key === 'paid') {
                      if (t.marketingPlanPoint) {
                          return t.pointCollectsSum
                      } else {
                          return t.toPaid
                      }
                  }

                    if (key === 'orderDate') {
                        if (t.marketingPlanPoint?.contactThreadCartBox?.contactThreadCartOrder?.orderDate) {
                            return this.formatDate(t.marketingPlanPoint?.contactThreadCartBox?.contactThreadCartOrder?.orderDate.date, {
                                month: 'numeric',
                                day: 'numeric',
                                year: 'numeric'
                            }, this.$i18n.locale)
                        } else if (t.contactThreadCartOrder?.orderDate) {
                          return this.formatDate(t.contactThreadCartOrder?.orderDate.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, this.$i18n.locale)
                        }
                    }

                  if (key === 'contact') {
                      if (t?.contactThread?.contact) {
                          return this.getUserName(t.contactThread.contact)
                      }
                      else if (t?.marketingPlanPoint?.contactThreadCartBox?.contactThread?.contact) {
                          return this.getUserName(t.marketingPlanPoint.contactThreadCartBox.contactThread.contact)
                      }
                      else if (t?.contactThreadCartOrder?.contactThreadCartBox?.contactThread?.contact) {
                          return this.getUserName(t.contactThreadCartOrder.contactThreadCartBox.contactThread.contact)
                      }
                  }
                  if (key === 'thread') {
                      if (t?.contactThread) {
                       return t.contactThread.name
                      }
                      else if (t?.marketingPlanPoint?.contactThreadCartBox?.contactThread) {
                       return t.marketingPlanPoint.contactThreadCartBox.contactThread.name
                      }
                      else if (t?.contactThreadCartOrder?.contactThreadCartBox?.contactThread) {
                       return t.contactThreadCartOrder.contactThreadCartBox.contactThread.name
                      }
                  }
                  if (key === 'contactThreadCartBoxProduct' && t?.contactThreadCartBoxProduct) return this.getTranslationsField(t.contactThreadCartBoxProduct, 'name')
                  if (key === 'seller' && t[key]) {
                      if (t?.marketingPlanPoint?.contactThreadCartBox?.contactThreadCartOrder?.seller) {
                          return this.getUserName(t.marketingPlanPoint.contactThreadCartBox.contactThreadCartOrder.seller)
                      } else if (t?.contactThreadCartOrder?.seller) {
                          return this.getUserName(t.contactThreadCartOrder.seller)
                      }
                  }
                  if (key === 'user') {
                      if (t?.marketingPlanPoint?.user) {
                          return this.getUserName(t.marketingPlanPoint.user)
                      } else if (t.marketingPlanPointUser) {
                          return this.getUserName(t.marketingPlanPointUser)
                      }
                  }
                  if (key === 'type' && t[key]) return this.$i18n.t(`${t[key]}`)
                  if (key === 'orderStatus' && t[key]) return this.$i18n.tc(`offer.status.${t[key]}`, 2)
                  if (key === 'status' && t[key]) return this.$i18n.t(`PaymentStatuses.${t[key]}`)
                  if (key === 'paymentMethod' && t?.contactThreadCartBox) return this.$i18n.t(t.contactThreadCartBox.paymentType.toLowerCase())
                  if (typeof t[key] === 'boolean') return t[key] ? 1 : 0
                  if (t[key]?.date) return this.formatDate(t[key].date, { month: 'numeric', day: 'numeric', year: 'numeric' }, this.$i18n.locale)

                  return t[key] ?? ''
              }).flat(Infinity)),
          ]

          const headers = order.map(({ label }) => this.$i18n.t(label))
          aoaToExport.unshift(headers)

          const ws = XLSX.utils.aoa_to_sheet(aoaToExport);
          XLSX.utils.book_append_sheet(wb, ws, 'Commissions');
          XLSX.writeFile(wb, 'Commissions.xlsx');

          this.exportLoading = false
      },
  },
}
</script>
