<template>
  <validation-observer
    ref="newProjectForm"
    tag="form"
  >
    <b-row style="row-gap: .5rem">
      <!--      New Task: Title      -->
      <validation-provider
        v-slot="{ errors }"
        class="col-12"
        rules="required|max-input"
        :name="$t('Title')"
      >
        <b-form-group :label="$t('Title')">
          <b-form-input
            v-model="name"
            :disabled="!isEdit"
            :placeholder="$t('Title')"
            :state="errors.length > 0 ? false:null"
            @input="e => $emit('getTitle', e)"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!--      Project      -->
      <validation-provider
        v-slot="{ errors }"
        class="col-12 mt-n50"
        rules="required"
        :name="$t('Offer.Project')"
      >
        <b-form-group>
          <sw-select
            :name="$t('Offer.Project')"
            :disabled="!isEdit || Boolean(task.id)"
            class="mb-0"
          >
            <v-select
              v-model="project"
              :placeholder="$t('Offer.Project')"
              :options="projects"
              :disabled="!isEdit || Boolean(task.id)"
              :filterable="false"
              label="name"
              item-value="id"
              item-text="name"
              :state="errors.length > 0 ? false:null"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!--      User      -->
      <validation-provider
        v-slot="{ errors }"
        class="col-12 mt-n50"
        :name="$t('SelectUsers')"
      >
        <b-form-group>
          <sw-select
            :name="$t('SelectUsers')"
            :disabled="!isEdit"
            class="mb-0"
          >
            <v-select
              id="offer-thread-1"
              v-model="assignedUsers"
              :options="userList"
              label="firstName"
              :filterable="false"
              :state="errors.length > 0 ? false:null"
              multiple
              :placeholder="$t('SelectUsers')"
              :disabled="!isEdit"
              @search="loadUsers"
              @select="save"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>
                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>

              <template #option="{ firstName, lastName, avatar }">
                <div class="d-flex align-items-center">
                  <avatar
                    :user="{ firstName, lastName, avatar }"
                    class="mr-25"
                  />
                </div>
              </template>

              <template #selected-option="{ firstName, lastName, avatar }">
                <div class="d-flex">
                  <avatar
                    :user="{ firstName, lastName, avatar }"
                    class="mr-25"
                    text-variant="text-white"
                  />
                </div>
              </template>
            </v-select>
          </sw-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!--      Time      -->
      <div class="col-12 mt-n50">
        <!--    Task time -->
        <b-form-checkbox
          v-model="isWholeDay"
          variant="primary"
          :disabled="!isEdit"
          class="custom-control-primary"
        >
          {{ $t('WholeDay') }}
        </b-form-checkbox>

        <b-row class="mt-50">
          <b-col :md="isWholeDay ? 12 : 6">
            <sw-select :name="isWholeDay ? $t('TaskTerm') : $t('TaskStartTerm')">
              <sw-select-fpr
                :is-empty="startAt"
                @clear="() => {
                  startAt = ''

                  if (isWholeDay) endAt = ''
                }"
              >
                <flat-pickr
                  :key="`time_${isWholeDay}`"
                  v-model="startAt"
                  :disabled="!isEdit"
                  :placeholder="isWholeDay ? $t('TaskTerm') : $t('TaskStartTerm')"
                  class="form-control"
                  :config="{ ...flatPickerConfig, enableTime: true, locale: getCalendarLocale($i18n.locale), maxDate: endAt && !isWholeDay ? endAt : '', dateFormat: 'Y-m-d H:i' }"
                />
              </sw-select-fpr>
            </sw-select>
          </b-col>

          <b-col
            v-if="!isWholeDay"
            md="6"
          >
            <sw-select :name="isWholeDay ? $t('TaskTerm') : $t('TaskEndTerm')">
              <sw-select-fpr
                :is-empty="endAt"
                @clear="endAt = ''"
              >
                <flat-pickr
                  :key="`time_end_${isWholeDay}`"
                  v-model="endAt"
                  :disabled="!isEdit"
                  :placeholder="isWholeDay ? $t('TaskTerm') : $t('TaskEndTerm')"
                  class="form-control"
                  :config="{ ...flatPickerConfig, enableTime: !isWholeDay, locale: getCalendarLocale($i18n.locale), dateFormat: isWholeDay ? 'Y-m-d' : 'Y-m-d H:i', minDate: startAt ? startAt : '' }"
                />
              </sw-select-fpr>
            </sw-select>
          </b-col>
        </b-row>
      </div>

      <!--      Categories      -->
      <categories
        :is-loading="isLoading"
        :is-edit="isEdit"
        class="col-12"
        :categories="projectTaskCategories"
        @new="addCategory"
        @remove="removeCategory"
      />

      <!--      Description      -->
      <b-form-group
        class="col-12"
        :label="$t('note.Description')"
      >
        <b-form-textarea
          v-model="description"
          style="height: 30rem"
          :disabled="!isEdit"
          :placeholder="$t('note.Description')"
        />
      </b-form-group>

      <b-overlay
        no-wrap
        :show="isLoading || isMeLoading"
        spinner-variant="primary"
      />
    </b-row>
  </validation-observer>
</template>

<script>
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import { BFormTextarea, VBTooltip } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Categories from '@/pages/projects/components/new/components/Categories.vue'
import { mapGetters } from 'vuex'
import { GET_PROJECTS } from '@/@constants/mutations'
import moment from 'moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { USERS_SEARCH } from '@/@constants/fields'

export default {
  components: {
    Categories,
    BFormTextarea,
    flatPickr,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSaveAllowed: {
      type: Boolean,
      required: false,
      default: false,
    },
    task: {
      type: Object,
      required: true,
    },
    projectId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data: () => ({
    isMeLoading: false,

    isWholeDay: false,

    name: '',
    description: '',

    startAt: '',
    endAt: '',

    flatPickerConfig: {
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      time_24hr: true,
      locale: [pl, en],
    },

    projects: [],
    project: '',

    threads: [],
    thread: '',

    projectTaskCategories: [],

    userList: [],
    assignedUsers: [],
  }),
  watch: {
    task: {
      deep: true,
      handler() {
        this.loadTask()
      },
    },

    isWholeDay: {
      deep: true,
      handler(n) {
        if (n) {
          this.startAt = this.startAt ? moment(this.startAt).format('YYYY-MM-DD HH:mm') : ''
          this.endAt = this.startAt ? moment(this.startAt, 'YYYY-MM-DD HH:mm').add('hours', 23.99).format('YYYY-MM-DD HH:mm') : ''
        }
      },
    },

    name() { this.save() },
    project() { this.save() },
    assignedUsers() { this.save() },
    startAt() { this.save() },
    endAt() { this.save() },
    description() { this.save() },
    projectTaskCategories() { this.save() },
  },
  mounted() {
    this.ini()
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  emits: ['getTitle', 'save'],
  methods: {
    ini() {
      this.loadProjects()

      this.loadTask()
    },

    loadTask() {
      this.isMeLoading = true
      const { task } = this

      if (task.id) {
        this.name = task.name
        this.description = task.description
        this.project = task.project
        this.assignedUsers = task.assignedUsers
        this.projectTaskCategories = task.projectTaskCategories
        this.startAt = (task.startAt?.date || task.startAt || '').slice(0, 16)
        this.endAt = (task.endAt?.date || task.endAt || '').slice(0, 16)
      }

      if (!this.isWholeDay) {
        this.isWholeDay = moment(this.endAt).diff(moment(this.startAt), 'days', true) === 1
        || Math.round(moment(this.endAt).diff(moment(this.startAt), 'hours', true) * 10) / 10 === 24
      }

      if (this.isEdit) this.$nextTick(() => this.$refs.newProjectForm.reset())
      this.isMeLoading = false
    },

    getPayload() {
      const payload = {
        name: this.name,
        description: this.description,
        startAt: this.startAt || '',
        endAt: this.endAt || '',
        project: this.project,
        assignedUsers: this.assignedUsers,
        projectTaskCategories: this.projectTaskCategories,
      }

      if (!this.isWholeDay) {
        this.isWholeDay = moment(this.endAt).diff(moment(this.startAt), 'days', true) === 1
        || Math.round(moment(this.endAt).diff(moment(this.startAt), 'hours', true) * 10) / 10 === 24
      }

      if (this.isWholeDay) {
        payload.startAt = payload.startAt ? moment(payload.startAt).format('YYYY-MM-DD HH:mm') : ''
        payload.endAt = payload.startAt ? moment(payload.startAt, 'YYYY-MM-DD HH:mm').add('hours', 23.99).format('YYYY-MM-DD HH:mm') : ''
      }

      if (this.task?.id) payload.id = this.task.id

      return payload
    },

    save() {
      if (this.isEdit) {
        if (this.isWholeDay) {
          this.startAt = this.startAt ? moment(this.startAt).format('YYYY-MM-DD HH:mm') : ''
          this.endAt = this.startAt ? moment(this.startAt, 'YYYY-MM-DD HH:mm').add('hours', 23.99).format('YYYY-MM-DD HH:mm') : ''
        }

        this.validationForm()
          .then(() => {
            const payload = this.getPayload()

            if (payload) this.$emit('save', payload)
          })
      }
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.newProjectForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    addCategory(category) { this.projectTaskCategories.push(category) },

    removeCategory(id) { this.projectTaskCategories.splice(this.projectTaskCategories.findIndex(category => category.id === id), 1) },

    loadProjects() {
      this.isMeLoading = true

      this.$store.dispatch(`projects/${GET_PROJECTS}`)
        .then(res => {
          this.projects = res

          if (this.projectId) {
            this.project = res.find(project => project.id === String(this.projectId))
          }
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => {
          this.isMeLoading = false

          if (this.isEdit) this.$nextTick(() => this.$refs.newProjectForm.reset())
        })
    },

    async loadUsers(search, isLoading) {
      // eslint-disable-next-line no-unused-vars
      const { project, projects } = this

      // eslint-disable-next-line no-unused-vars
      const filters = {}

      if (project) filters.allowedUsers = projects.find(p => p.id === (project?.id || project)).assignedUsers.map(u => u.id)

      this.userList = await this.selectSearch(search, isLoading, USERS_SEARCH, 'firstName,lastName', '1/users', 25, filters)
    },
  },
}
</script>
