var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"newProjectForm",attrs:{"tag":"form"}},[_c('b-row',{staticStyle:{"row-gap":".5rem"}},[_c('validation-provider',{staticClass:"col-12",attrs:{"rules":"required|max-input","name":_vm.$t('Title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Title')}},[_c('b-form-input',{attrs:{"disabled":!_vm.isEdit,"placeholder":_vm.$t('Title'),"state":errors.length > 0 ? false:null},on:{"input":function (e) { return _vm.$emit('getTitle', e); }},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{staticClass:"col-12 mt-n50",attrs:{"rules":"required","name":_vm.$t('Offer.Project')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('sw-select',{staticClass:"mb-0",attrs:{"name":_vm.$t('Offer.Project'),"disabled":!_vm.isEdit || Boolean(_vm.task.id)}},[_c('v-select',{attrs:{"placeholder":_vm.$t('Offer.Project'),"options":_vm.projects,"disabled":!_vm.isEdit || Boolean(_vm.task.id),"filterable":false,"label":"name","item-value":"id","item-text":"name","state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{staticClass:"col-12 mt-n50",attrs:{"name":_vm.$t('SelectUsers')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('sw-select',{staticClass:"mb-0",attrs:{"name":_vm.$t('SelectUsers'),"disabled":!_vm.isEdit}},[_c('v-select',{attrs:{"id":"offer-thread-1","options":_vm.userList,"label":"firstName","filterable":false,"state":errors.length > 0 ? false:null,"multiple":"","placeholder":_vm.$t('SelectUsers'),"disabled":!_vm.isEdit},on:{"search":_vm.loadUsers,"select":_vm.save},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
return [(search.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('TypeToSearch'))+" ")])]}},{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var avatar = ref.avatar;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":{ firstName: firstName, lastName: lastName, avatar: avatar }}})],1)]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var avatar = ref.avatar;
return [_c('div',{staticClass:"d-flex"},[_c('avatar',{staticClass:"mr-25",attrs:{"user":{ firstName: firstName, lastName: lastName, avatar: avatar },"text-variant":"text-white"}})],1)]}}],null,true),model:{value:(_vm.assignedUsers),callback:function ($$v) {_vm.assignedUsers=$$v},expression:"assignedUsers"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('div',{staticClass:"col-12 mt-n50"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"variant":"primary","disabled":!_vm.isEdit},model:{value:(_vm.isWholeDay),callback:function ($$v) {_vm.isWholeDay=$$v},expression:"isWholeDay"}},[_vm._v(" "+_vm._s(_vm.$t('WholeDay'))+" ")]),_c('b-row',{staticClass:"mt-50"},[_c('b-col',{attrs:{"md":_vm.isWholeDay ? 12 : 6}},[_c('sw-select',{attrs:{"name":_vm.isWholeDay ? _vm.$t('TaskTerm') : _vm.$t('TaskStartTerm')}},[_c('sw-select-fpr',{attrs:{"is-empty":_vm.startAt},on:{"clear":function () {
                _vm.startAt = ''

                if (_vm.isWholeDay) { _vm.endAt = '' }
              }}},[_c('flat-pickr',{key:("time_" + _vm.isWholeDay),staticClass:"form-control",attrs:{"disabled":!_vm.isEdit,"placeholder":_vm.isWholeDay ? _vm.$t('TaskTerm') : _vm.$t('TaskStartTerm'),"config":Object.assign({}, _vm.flatPickerConfig, {enableTime: true, locale: _vm.getCalendarLocale(_vm.$i18n.locale), maxDate: _vm.endAt && !_vm.isWholeDay ? _vm.endAt : '', dateFormat: 'Y-m-d H:i'})},model:{value:(_vm.startAt),callback:function ($$v) {_vm.startAt=$$v},expression:"startAt"}})],1)],1)],1),(!_vm.isWholeDay)?_c('b-col',{attrs:{"md":"6"}},[_c('sw-select',{attrs:{"name":_vm.isWholeDay ? _vm.$t('TaskTerm') : _vm.$t('TaskEndTerm')}},[_c('sw-select-fpr',{attrs:{"is-empty":_vm.endAt},on:{"clear":function($event){_vm.endAt = ''}}},[_c('flat-pickr',{key:("time_end_" + _vm.isWholeDay),staticClass:"form-control",attrs:{"disabled":!_vm.isEdit,"placeholder":_vm.isWholeDay ? _vm.$t('TaskTerm') : _vm.$t('TaskEndTerm'),"config":Object.assign({}, _vm.flatPickerConfig, {enableTime: !_vm.isWholeDay, locale: _vm.getCalendarLocale(_vm.$i18n.locale), dateFormat: _vm.isWholeDay ? 'Y-m-d' : 'Y-m-d H:i', minDate: _vm.startAt ? _vm.startAt : ''})},model:{value:(_vm.endAt),callback:function ($$v) {_vm.endAt=$$v},expression:"endAt"}})],1)],1)],1):_vm._e()],1)],1),_c('categories',{staticClass:"col-12",attrs:{"is-loading":_vm.isLoading,"is-edit":_vm.isEdit,"categories":_vm.projectTaskCategories},on:{"new":_vm.addCategory,"remove":_vm.removeCategory}}),_c('b-form-group',{staticClass:"col-12",attrs:{"label":_vm.$t('note.Description')}},[_c('b-form-textarea',{staticStyle:{"height":"30rem"},attrs:{"disabled":!_vm.isEdit,"placeholder":_vm.$t('note.Description')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('b-overlay',{attrs:{"no-wrap":"","show":_vm.isLoading || _vm.isMeLoading,"spinner-variant":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }