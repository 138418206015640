<template>
  <b-alert
    show
    variant="primary"
    class="px-50 py-1 d-flex align-items-center justify-content-start"
    :class="{ 'selected border-primary': isSelected }"
  >
    <!--    <b-avatar variant="primary">-->
    <!--      <feather-icon icon="HexagonIcon" />-->
    <!--    </b-avatar>-->

    <div class="d-flex flex-column ml-75">
      <div class="mb-0 text-primary d-flex justify-content-between w-100 align-items-center flex-wrap">
        <b-badge v-b-tooltip
                 variant="primary"
                 class="flex-grow-1 mx-25 d-flex align-items-center font-medium-1 mt-25"
                 :title="$t('Sum')"
        >
          <feather-icon icon="CircleIcon"
                        class="mr-25"
                        size="25"
                        style="font-size: 15px; height: 15px"
          />
          {{ valAll || 0 | priceFormat }} PLN
        </b-badge>
        <b-badge v-b-tooltip
                 variant="primary"
                 class="flex-grow-1 mx-25 d-flex align-items-center font-medium-1 mt-25"
                 :title="$t('group')"
        >
          <feather-icon icon="UsersIcon"
                        class="mr-25"
                        size="25"
                        style="font-size: 15px; height: 15px"
          />
          {{ valGroup || 0 | priceFormat }} PLN
        </b-badge>
        <b-badge v-b-tooltip
                 variant="primary"
                 class="flex-grow-1 mx-25 d-flex align-items-center font-medium-1 mt-25"
                 :title="$t('own')"
        >
          <feather-icon icon="UserIcon"
                        class="mr-25"
                        size="25"
                        style="font-size: 15px; height: 15px"
          />
          {{ val || 0 | priceFormat }} PLN
        </b-badge>
      </div>

      <p class="text-dark mb-n50">
        {{ name }}

        <b-button
          v-b-tooltip.focus.v-primary
          variant="flat-primary"
          size="sm"
          :title="tip"
          class="btn-icon p-25"
        >
          <feather-icon icon="HelpCircleIcon" />
        </b-button>
      </p>
    </div>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </b-alert>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'SwItem',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    tip: {
      type: String,
      required: true,
    },
    val: {
      type: Number,
      required: false,
    },
    valGroup: {
      type: Number,
      required: false,
    },
    valAll: {
      type: Number,
      required: false,
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
