<template>
  <b-card
    style="min-width: 100%"
    class="mb-0 rounded-0 shadow-none border-bottom"
    :class="{ 'pb-75': hideCollapse }"
    no-body
  >
    <b-card-body class="d-flex flex-column pb-50">
      <div
        class="d-flex flex-wrap align-items-center justify-content-between mb-25"
        style="gap: .34rem .5rem"
      >
        <div
          class="d-flex flex-wrap justify-content-between w-100"
          style="gap: .24rem .5rem"
        >
          <div
            class="d-flex justify-content-between align-items-center"
            style="gap: .5rem"
          >
            <!--      Move Button      -->
            <move-button
              v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY]) && !hideCollapse"
              :target-id="Number(target.id)"
              :is-task="isTask"
              :is-edit="isEdit"
              :status-id="Number((target.projectStatus || target.projectTaskStatus).id)"
              :project-id="project ? Number(project.id) : null"
              small
              @update="$emit('update')"
            />

            <!--      Show Tasks/Micro Tasks      -->
            <!--            <b-button-->
            <!--              v-b-tooltip.hover.v-primary-->
            <!--              size="sm"-->
            <!--              variant="flat-primary"-->
            <!--              class="p-25 text-nowrap"-->
            <!--              :to="{ name: !isTask ? 'projects/tasks' : `projects/tasks/micro`, params: {-->
            <!--                project: !isTask ? target.id : target.project.id,-->
            <!--                task: !isTask ? null : target.id-->
            <!--              } }"-->
            <!--              :title="$t(!isTask ? 'ShowProjectTasks' : 'ShowMicroTaskTasks')"-->
            <!--            >-->
            <!--              <feather-icon icon="CheckSquareIcon" />-->
            <!--            </b-button>-->

            <!--      Task Title      -->
            <router-link
              class="ml-n25"
              :to="{ path: !isTask ? `/projects/edit/${target.id}` : `/projects/${target.project.id}/tasks/edit/${target.id}`, params: target.id }"
            >
              <strong
                v-b-tooltip.hover
                class="mb-0 cursor-pointer text-dark text-nowrap"
                :title="target.name"
              >
                <template v-if="target.name.length">
                  <span class="d-none d-md-inline-block">
                    {{ target.name }}
                  </span>

                  <span class="d-md-none">
                    {{ target.name | textIsland(30, '…') }}
                  </span>
                </template>

                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
              </strong>
            </router-link>

            <!--      Time      -->
            <date
              :date="target.endAt ? target.endAt.date : ''"
              :title="$t( !isTask ? 'ProjectCompletionDate' : 'TaskCompletionDate')"
              hide-time
            />
          </div>

          <div class="d-flex">
            <!--      Target Info      -->
            <target-info
              :is-loading="isLoading"
              :infos="{
                documents: getDocumentLength(target.projectDocuments || target.projectTasks),
                completeTasks: (
                  (
                    typeof target.projectTaskCompletedCount !== 'undefined'
                      ? target.projectTaskCompletedCount || 0
                      : target.projectTaskCompletedMicroTasksCount || 0
                  )
                  + (
                    typeof target.projectTaskRejected !== 'undefined'
                      ? target.projectTaskRejected || 0
                      : target.projectTaskRejectedMicroTasksCount || 0
                  )
                ),
                tasks: target.projectTasksCount || 0,
                microTasks: (target.projectTaskMicroTasksCount || 0),
                endangeredTasks: (target.projectTaskMicroTasksCloseToDeadlineCount || 0),
                endangered: (target.projectTaskCloseToDeadlineCount || 0),
                projectStatus: target.projectStatus,
                assignedUsers: target.assignedUsers || [],
              }"
              class="mb-25"
              :is-task="isTask"
            />
          </div>
        </div>
      </div>

      <div
        class="d-flex justify-content-start align-items-center flex-wrap"
        style="gap: .5rem"
      >
        <b-badge
          v-if="isTask"
          v-b-tooltip.hover.v-primary
          :title="$t('offer.product.project')"
          variant="light-primary"
          style="font-size: .7rem"
        >
          <template v-if="target.project || (project && project.name)">
            <span class="d-none d-md-inline-block">
              {{ target.project.name || project.name }}
            </span>

            <span class="d-md-none">
              {{ target.project.name || project.name | textIsland(34) }}
            </span>
          </template>

          <feather-icon
            v-else
            icon="MinusIcon"
          />
        </b-badge>

        <template v-if="isTask">
          <template v-if="target.projectTaskCategories.length">
            <b-badge
              v-for="category in target.projectTaskCategories"
              :key="category.id"
              v-b-tooltip.hover.v-warning
              :title="$t('Category')"
              variant="light-warning"
              style="font-size: .7rem"
            >
              <span class="d-none d-md-inline-block">{{ category.name }}</span>
              <span class="d-md-none">{{ category.name | textIsland(34) }}</span>
            </b-badge>
          </template>

          <b-badge
            v-else
            v-b-tooltip.hover.v-warning
            :title="$t('Category')"
            variant="light-warning"
            style="font-size: .7rem"
          >
            <feather-icon
              icon="MinusIcon"
            />
          </b-badge>
        </template>
      </div>

      <!--      Target Description      -->
      <span
        v-if="target.description.length"
        class="w-100 my-25"
        style="font-size: .8rem"
      >
        <span class="d-none d-md-inline-block">{{ target.description }}</span>
        <span class="d-md-none">{{ target.description | truncate(120, '…') }}</span>
      </span>

      <!--      Target Tags      -->
      <target-tags
        :is-loading="isLoading"
        :tags="!isTask ? target.projectTasksTags || [] : target.projectTaskTag || []"
        :is-task="isTask"
      />

      <b-button
        v-if="!hideCollapse"
        v-b-toggle="`details-${target.id}-${index}`"
        variant="flat-secondary"
        class="w-100 my-25"
        size="sm"
      >
        {{ $t('Details') }}

        <feather-icon icon="ChevronDownIcon" />
      </b-button>

      <b-collapse
        v-if="!hideCollapse"
        :id="`details-${target.id}-${index}`"
        class="mt-25"
      >
        <div class="border rounded px-1 pt-1">
          <!--      Task      -->
          <b-tabs lazy>
            <!--      Task: Information      -->
            <b-tab
              :title="$t('Information')"
              active
            >
              <!--      Task Preview: Options      -->
              <information-project
                v-if="!isTask"
                :is-loading="isLoading"
                :project="target"
              />

              <!--      Task Preview: Options      -->
              <information-task
                v-else
                :is-loading="isLoading"
                :task="target"
              />
            </b-tab>

            <!--      Task: Comments      -->
            <b-tab :title="$t('Comments')">
              <comments
                :is-loading="isLoading"
                :is-task="isTask"
                :project-id="project ? parseInt(project.id) : null"
                :target-id="target ? parseInt(target.id) : null"
              />
            </b-tab>

            <!--      Task: Documents      -->
            <b-tab :title="$t('Documents')">
              <documents
                :key="`project-document-${target.id}`"
                :is-loading="isLoading"
                :project-id="!isTask ? target.id : target.project.id"
                :task-id="!isTask ? null : target.id"
              />
            </b-tab>

            <!--      Task: Target Progress      -->
            <b-tab
              v-if="!isTask"
              :title="$t('ProjectProgress')"
            >
              <!--      Target Categories Progres      -->
              <progress-project
                :is-loading="isLoading"
                :project="target"
              />
            </b-tab>
          </b-tabs>
        </div>
      </b-collapse>

      <b-overlay
        no-wrap
        :show="isLoading"
        spinner-variant="primary"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCollapse, BTab, BTabs, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import { formatDate } from '@core/utils/filter'
import Date from '@/pages/projects/components/new/components/Date.vue'
import InformationProject from '@/pages/projects/components/new/InformationProject.vue'
import InformationTask from '@/pages/projects/components/new/InformationTask.vue'
import Comments from '@/pages/projects/components/new/Comments.vue'
import Documents from '@/pages/projects/components/new/Documents.vue'
import ProgressProject from '@/pages/projects/components/new/ProgressProject.vue'
import TargetInfo from '@/pages/projects/components/new/components/Info.vue'
import TargetTags from '@/pages/projects/components/new/components/Tags.vue'
import MoveButton from '@/pages/projects/components/new/components/MoveButton.vue'
import moment from 'moment/moment'

export default {
  components: {
    BTabs,
    BTab,
    BCollapse,
    Date,
    InformationProject,
    InformationTask,
    Comments,
    Documents,
    ProgressProject,
    TargetInfo,
    TargetTags,
    MoveButton,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    index: {
      type: Number,
      required: false,
      default: -1,
    },
    target: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: false,
      default: null,
    },
    isTask: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideCollapse: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update'],
  data: () => ({
    mTags: false,

    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
  },
  methods: {
    formatDate,
    removeTarget() {
      this.$root.$emit('bv::hide::popover')
      this.$emit('changeTarget', null)
    },

    getEndangered(list) {
      if (!list || !list.length) return ''

      return list.filter(item => (item.endAt ? moment(item.endAt.date, 'YYYY-MM-DD hh:mm:ss').isSame(moment(), 'day') : ''))
    },

    getDocumentLength(list) {
      if (!list.length) return 0

      return list.map(doc => doc.files.length).reduce((a, b) => a + b)
    },
  },
}
</script>
