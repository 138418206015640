<template>
  <sw-filters
    v-if="showFilters"
    mt
    :shorts="[
      {
        title: $t('SelectProjects'),
        value: () => filters.project ? projectList.find(p => Number(p.id) === Number(filters.project)).name : null,
        clear: () => { filters.project = '' }
      },
      {
        title: $t('SelectTasks'),
        value: () => filters.task ? taskList.find(p => Number(p.id) === Number(filters.task)).name : null,
        clear: () => { filters.task = '' }
      },
      {
        title: $t('Tags'),
        value: () => filters.tags.map(tag => tagList.find(t => Number(t.id) === Number(tag)).name).join(', '),
        clear: () => { filters.tags = [] }
      },
      {
        title: $t('SelectUsers'),
        type: 'avatar',
        value: () => filters.assignedUsers,
        clear: () => { filters.assignedUsers = [] }
      },
      {
        title: $t('SelectContacts'),
        type: 'avatar',
        value: () => filters.contactThreads,
        clear: () => { filters.contactThreads = [] }
      },
      {
        title: $t('TaskCategories'),
        value: () => filters.categories.map(category => categoryList.find(({ id }) => id === category).name).join(', '),
        clear: () => { filters.categories = [] }
      },
      {
        title: $t('ProjectCreationDate'),
        value: () => filters.createdAt.project,
        clear: () => { filters.createdAt.project = '' }
      },
      {
        title: $t('TasCcreationDate'),
        value: () => filters.createdAt.task,
        clear: () => { filters.createdAt.task = '' }
      },
      {
        title: $t('TheCreationDateOfMicroTask'),
        value: () => filters.createdAt.microTask,
        clear: () => { filters.createdAt.microTask = '' }
      },
      {
        title: $t('ProjecCcompletionDate'),
        value: () => filters.completionAt.project,
        clear: () => { filters.completionAt.project = '' }
      },
      {
        title: $t('TaskCompletionDate'),
        value: () => filters.completionAt.task,
        clear: () => { filters.completionAt.task = '' }
      },
      {
        title: $t('DeadlineForCompletingMicroTask'),
        value: () => filters.completionAt.microTask,
        clear: () => { filters.completionAt.microTask = '' }
      },
      {
        variant: 'primary',
        value: () => filters.onDeadline ? $t('ShowAtRiskProjects') : null,
        clear: () => { filters.onDeadline = false }
      },
      {
        variant: 'primary',
        value: () => filters.expired ? $t('ShowProjectsNotCompletedOnTime') : null,
        clear: () => { filters.expired = false }
      },
    ]"
  >
    <div
      class="d-flex flex-column"
      style="gap: 1.5rem"
    >
      <b-row style="row-gap: 1rem">
        <b-col
          v-if="isTask || isMicro"
          :md="!isTask ? 6 : 4"
          :lg="!isTask ? 6 : 4"
          sm="12"
        >
          <sw-select :name="$t('SelectProjects')">
            <v-select
              v-model="filters.project"
              :options="projectList"
              :placeholder="$t('Offer.Project')"
              :filterable="false"
              label="name"
              :reduce="item => item.id.toString()"
              item-value="id"
              item-text="name"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          v-if="isMicro"
          sm="12"
          :md="!isTask ? 6 : 4"
          :lg="!isTask ? 6 : 4"
        >
          <sw-select :name="$t('SelectTasks')">
            <v-select
              v-model="filters.task"
              :options="taskList"
              :placeholder="$t('SelectTasks')"
              :filterable="false"
              label="name"
              :reduce="item => item.id.toString()"
              item-value="id"
              item-text="name"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          :md="!isTask ? 6 : 4"
          :lg="!isTask ? 6 : 4"
        >
          <sw-select :name="$t('ChooseTags')">
            <v-select
              v-model="filters.tags"
              :placeholder="$t('ChooseTags')"
              :options="tagList"
              :filterable="false"
              label="name"
              multiple
              :reduce="item => item.id.toString()"
              item-value="id"
              item-text="name"
            >
              <template #option="{ name }">
                {{ name | textIsland(28) }}
              </template>

              <template #selected-option="{ name }">
                {{ name | textIsland(28) }}
              </template>

              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          :md="!isTask ? 6 : 4"
          :lg="!isTask ? 6 : 4"
        >
          <sw-select :name="$t('SelectContacts')">
            <v-select
              id="offer-thread-1"
              v-model="filters.contactThreads"
              :options="contactThreadList"
              label="name"
              :filterable="false"
              :selectable="(thread) => thread.offersTotal < system.contactThreadOffersLimit"
              multiple
              :placeholder="$t('SelectContacts')"
              @search="loadThreads"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>
                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>
              <template #option="{ name, contact, offersTotal }">
                <div class="d-flex flex-wrap align-items-center">
                  <avatar
                    :user="contact"
                    size="sm"
                    :text-nowrap="false"
                    class="mr-25"
                  >
                    <template #after>
                      <p class="text-primary mb-0 dd">
                        – {{ name }}
                      </p>
                    </template>
                  </avatar>

                  <feather-icon
                    v-if="offersTotal >= system.contactThreadOffersLimit"
                    v-b-tooltip
                    icon="AlertCircleIcon"
                    class="ml-50"
                    :title="$tc('ThreadOfferCountIsToBig', system.contactThreadOffersLimit, { limit: system.contactThreadOffersLimit })"
                  />
                </div>
              </template>

              <template #selected-option="{ name, contact }">
                <div class="d-flex flex-wrap">
                  <avatar
                    :user="contact"
                    size="sm"
                    class="mr-25"
                    :text-nowrap="false"
                    text-variant="text-white"
                  >
                    <template #after>
                      <p class="text-white mb-0">
                        – {{ name }}
                      </p>
                    </template>
                  </avatar>
                </div>
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          :md="!isTask ? 6 : 4"
          :lg="!isTask ? 6 : 4"
        >
          <sw-select :name="$t('SelectUsers')">
            <v-select
              id="offer-thread-1"
              v-model="filters.assignedUsers"
              :options="userList"
              label="firstName"
              :filterable="false"
              multiple
              :placeholder="$t('SelectUsers')"
              @search="loadUsers"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>
                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>

              <template #option="{ firstName, lastName, avatar }">
                <div class="d-flex align-items-center">
                  <avatar
                    :user="{ firstName, lastName, avatar }"
                    class="mr-25"
                  />
                </div>
              </template>

              <template #selected-option="{ firstName, lastName, avatar }">
                <div class="d-flex">
                  <avatar
                    :user="{ firstName, lastName, avatar }"
                    class="mr-25"
                    text-variant="text-white"
                  />
                </div>
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          v-if="isTask"
          sm="12"
          :md="!isTask ? 6 : 4"
          :lg="!isTask ? 6 : 4"
        >
          <sw-select :name="$t('TaskCategories')">
            <v-select
              v-model="filters.categories"
              :placeholder="$t('TaskCategories')"
              :options="categoryList"
              :filterable="false"
              label="name"
              multiple
              :reduce="item => item.id.toString()"
              item-value="id"
              item-text="name"
            >
              <template #option="{ name }">
                {{ name | textIsland(28) }}
              </template>

              <template #selected-option="{ name }">
                {{ name | textIsland(28) }}
              </template>

              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>
      </b-row>

      <b-row style="row-gap: 1rem">
        <b-col
          v-if="!isTask && !isMicro"
          sm="12"
          :md="!isTask ? 6 : 4"
          :lg="!isTask ? 6 : 4"
        >
          <sw-select :name="$t('ProjectCreationDate')">
            <sw-select-fpr
              :is-empty="filters.createdAt.project"
              @clear="filters.createdAt.project = ''"
            >
              <flat-pickr
                v-model="filters.createdAt.project"
                class="form-control"
                :placeholder="$t('ProjectCreationDate')"
                :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>

        <b-col
          v-if="isTask"
          sm="12"
          :md="!isTask ? 6 : 4"
          :lg="!isTask ? 6 : 4"
        >
          <sw-select :name="$t('TasCcreationDate')">
            <sw-select-fpr
              :is-empty="filters.createdAt.task"
              @clear="filters.createdAt.task = ''"
            >
              <flat-pickr
                v-model="filters.createdAt.task"
                class="form-control"
                :placeholder="$t('TasCcreationDate')"
                :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>

        <b-col
          v-if="isMicro"
          sm="12"
          :md="!isTask ? 6 : 4"
          :lg="!isTask ? 6 : 4"
        >
          <sw-select :name="$t('TheCreationDateOfMicroTask')">
            <sw-select-fpr
              :is-empty="filters.createdAt.microTask"
              @clear="filters.createdAt.microTask = ''"
            >
              <flat-pickr
                v-model="filters.createdAt.microTask"
                class="form-control"
                :placeholder="$t('TheCreationDateOfMicroTask')"
                :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>

        <b-col
          v-if="!isTask && !isMicro"
          sm="12"
          :md="!isTask ? 6 : 4"
          :lg="!isTask ? 6 : 4"
        >
          <sw-select :name="$t('ProjecCcompletionDate')">
            <sw-select-fpr
              :is-empty="filters.completionAt.project"
              @clear="filters.completionAt.project = ''"
            >
              <flat-pickr
                v-model="filters.completionAt.project"
                class="form-control"
                :placeholder="$t('ProjecCcompletionDate')"
                :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>

        <b-col
          v-if="isTask"
          sm="12"
          :md="!isTask ? 6 : 4"
          :lg="!isTask ? 6 : 4"
        >
          <sw-select :name="$t('TaskCompletionDate')">
            <sw-select-fpr
              :is-empty="filters.completionAt.task"
              @clear="filters.completionAt.task = ''"
            >
              <flat-pickr
                v-model="filters.completionAt.task"
                class="form-control"
                :placeholder="$t('TaskCompletionDate')"
                :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>

        <b-col
          v-if="isMicro"
          sm="12"
          :md="!isTask ? 6 : 4"
          :lg="!isTask ? 6 : 4"
        >
          <sw-select :name="$t('DeadlineForCompletingMicroTask')">
            <sw-select-fpr
              :is-empty="filters.completionAt.microTask"
              @clear="filters.completionAt.microTask = ''"
            >
              <flat-pickr
                v-model="filters.completionAt.microTask"
                class="form-control"
                :placeholder="$t('DeadlineForCompletingMicroTask')"
                :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>
      </b-row>

      <b-row style="row-gap: .5rem">
        <b-col
          sm="12"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="filters.onDeadline"
            class="custom-control-primary"
          >
            {{ $t(!isTask && !isMicro ? 'ShowAtRiskProjects' : !isMicro ? 'ShowAtRiskJobs' : 'ShowMicroTasksAtRisk') }}
          </b-form-checkbox>

          <b-button
            v-b-tooltip.hover.v-primary
            :title="$t(!isTask && !isMicro ? 'ShowAtRiskProjectsOfUnfinishedTasksDeadlineIsInHoursInfo' : !isMicro ? 'ShowAtRiskOfUnfinishedTasksDeadlineIsInHoursInfo' : 'ShowMicroAtRiskOfUnfinishedTasksDeadlineIsInHoursInfo')"
            variant="flat-primary"
            class="btn-icon ml-25"
            size="sm"
          >
            <feather-icon icon="InfoIcon" />
          </b-button>
        </b-col>

        <b-col
          sm="12"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="filters.expired"
            class="custom-control-primary"
          >
            {{ $t(!isTask && !isMicro ? 'ShowProjectsNotCompletedOnTime' : !isMicro ? 'ShowUnperformedExpiredSentencesTheDeadlineHasAlreadyPassed' : 'ShowUnperformedExpiredSentencesTheDeadlineHasAlreadyPassedMicro') }}
          </b-form-checkbox>

          <!--          <b-button-->
          <!--            v-b-tooltip.hover.v-primary-->
          <!--            :title="$t(!isTask && !isMicro ? 'ShowAtRiskProjects' : !isMicro ? 'ShowAtRiskJobs' : 'ShowMicroTasksAtRisk')"-->
          <!--            variant="flat-primary"-->
          <!--            class="btn-icon ml-25"-->
          <!--            size="sm"-->
          <!--          >-->
          <!--            <feather-icon icon="InfoIcon" />-->
          <!--          </b-button>-->
        </b-col>
      </b-row>
    </div>
  </sw-filters>
</template>

<script>
/* eslint-disable */

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import { VBTooltip } from 'bootstrap-vue'
import {
  GET_PROJECT_TAGS,
  GET_PROJECT_TASK_CATEGORIES,
  GET_PROJECT_TASKS,
  GET_PROJECT_TASKS_FROM_PROJECT,
  GET_PROJECTS,
} from '@/@constants/mutations'
import { THREADS_SEARCH, USERS_SEARCH } from '@/@constants/fields'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectsFilters',
  components: {
    vSelect,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTask: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMicro: {
      type: Boolean,
      required: false,
      default: false,
    },
    projectId: {
      type: Number,
      required: false,
      default: null,
    },
    taskId: {
      type: Number,
      required: false,
      default: null,
    },
    startFilters: {
      type: Object,
      required: false,
      default: null,
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    isMeLoading: false,

    filters: {
      project: '',
      task: '',
      tags: [],
      contactThreads: [],
      assignedUsers: [],
      categories: [],
      createdAt: {
        project: '',
        task: '',
        microTask: '',
      },

      completionAt: {
        project: '',
        task: '',
        microTask: '',
      },

      onDeadline: false,
      expired: false,
    },

    projectList: [],
    taskList: [],
    tagList: [],
    categoryList: [],
    userList: [],
    contactThreadList: [],

    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },

    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  watch: {
    'filters.project': function (n) {
      if (n && this.isMicro) this.loadTasks(n)
    },
    filters: {
      deep: true,
      handler(n) { this.$emit('change', this.getPayload(n)) },
    },
  },
  emits: ['change'],
  mounted() { this.ini() },
  methods: {
    async ini() {
      await Promise.all([
        this.loadProjects(),
        this.loadTasks(),
        this.loadTags(),
        this.loadCategories()
      ])

      await this.$nextTick()

      if (this.projectId) {
        const filterProject = this.projectList.find(project => Number(project.id) === this.projectId)

        if (filterProject) this.filters.project = filterProject
      }

      if (this.taskId) {
        const filterTask = this.taskList.find(task => Number(task.id) === this.taskId)

        if (filterTask) this.filters.task = filterTask
      }

      await this.$nextTick()

      if (this.startFilters) {
        if (this.startFilters.assignedUsers) this.filters.assignedUsers = this.startFilters.assignedUsers
        if (this.startFilters.contactThreads) this.filters.contactThreads = this.startFilters.contactThreads.map(t => ({ id: t.id, name: `${t.firstName} ${t.lastName}`, contact: t }))
      }
    },

    getPayload(filters) {
      const payload = filters

      if (payload?.project?.id) payload.project = payload.project.id
      if (payload?.task?.id) payload.task = payload.task.id

      return payload
    },

    async loadProjects() {
      this.isMeLoading = true

      await this.$store.dispatch(`projects/${GET_PROJECTS}`)
        .then(res => { this.projectList = res })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isMeLoading = false })
    },

    async loadTasks(project) {
      this.isMeLoading = true

      const type = !project ? GET_PROJECT_TASKS : GET_PROJECT_TASKS_FROM_PROJECT

      await this.$store.dispatch(`tasks/${type}`, project && project.id ? project.id : project)
        .then(res => { this.taskList = res })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isMeLoading = false })
    },

    async loadTags() {
      this.isMeLoading = true

      this.$store.dispatch(`projectTags/${GET_PROJECT_TAGS}`)
        .then(res => {
          this.tagList = res
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isMeLoading = false })
    },

    async loadCategories() {
      this.isMeLoading = true

      this.$store.dispatch(`taskCategories/${GET_PROJECT_TASK_CATEGORIES}`)
        .then(res => { this.categoryList = res })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isMeLoading = false })
    },

    async loadThreads(search, isLoading) {
      this.contactThreadList = await this.selectSearch(search, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads')
    },

    async loadUsers(search, isLoading) {
      // eslint-disable-next-line no-unused-vars
      const { project, projects } = this

      // eslint-disable-next-line no-unused-vars
      const filters = {}

      if (project) filters.allowedUsers = projects.find(p => p.id === (project?.id || project)).assignedUsers.map(u => u.id)

      this.userList = await this.selectSearch(search, isLoading, USERS_SEARCH, 'firstName,lastName', '1/users', 25, filters)
    },
  },
}
</script>
