var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.checkRequiredPermissions([_vm.$roles.FULL_ACCESS]) || _vm.checkRequiredViewPermissions([_vm.$viewRanks.SHOW_MARKETING_POINTS_EXPORT]))?_c('div',{staticClass:"my-50"},[_c('b-button',{attrs:{"variant":"primary","size":"sm","disabled":_vm.exportLoading},on:{"click":_vm.getRecordsToExport}},[(!_vm.exportLoading)?_c('span',[_c('feather-icon',{attrs:{"icon":"DownloadIcon"}}),_vm._v("Export")],1):_c('span',[_c('b-spinner',{attrs:{"small":"","variant":"light"}})],1)])],1):_vm._e(),_c('b-card',[_c('sw-table',{attrs:{"table-id":"3fd44a8b916849b7a463cec648bb7087","show-columns":"","show-search":"","fields":_vm.tableFields,"pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"change-search-query":function($event){_vm.search = $event},"reload-content":_vm.loadData,"set-fields":function($event){_vm.tableFields = $event}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{attrs:{"items":_vm.points,"fields":_vm.fields,"striped":"","responsive":"","busy":_vm.loading,"show-empty":"","sort-by":_vm.sorter.sortBy,"sort-desc":_vm.sorter.sortDesc,"no-local-sorting":true},on:{"update:sortBy":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"sort-changed":function($event){Object.assign(_vm.sorter, $event); _vm.loadData()}},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(id)",fn:function(ref){
var index = ref.index;
return [_vm._v(" #"+_vm._s(_vm.getOrderNo(index, _vm.pagination, _vm.sorter))+" ")]}},{key:"cell(user)",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('avatar',{staticClass:"py-25",attrs:{"user":item.user}}):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(seller)",fn:function(ref){
var item = ref.item;
return [(item.contactThreadCartBox.contactThreadCartOrder && item.contactThreadCartBox.contactThreadCartOrder.seller)?_c('avatar',{staticClass:"py-25",attrs:{"user":item.contactThreadCartBox.contactThreadCartOrder.seller}}):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(contact)",fn:function(ref){
var item = ref.item;
return [(item.contactThreadCartBox.contactThread && item.contactThreadCartBox.contactThread.contact)?_c('div',[_c('avatar',{attrs:{"user":item.contactThreadCartBox.contactThread.contact}})],1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(thread)",fn:function(ref){
var item = ref.item;
return [(item.contactThreadCartBox.contactThread)?_c('div',[_vm._v(" "+_vm._s(item.contactThreadCartBox.contactThread.name)+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(products)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"text-nowrap",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(((_vm.$t('Products')) + ": " + (item.productsCount || 0)))+" ")])]}},{key:"cell(contactThreadCartBoxProduct)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTranslationsField(item.contactThreadCartBoxProduct))+" ")]}},{key:"cell(nettoPrice)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("priceFormat")(item.contactThreadCartBox.totalPriceWithDiscount))+" PLN ")])]}},{key:"cell(grossPrice)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("priceGrossFormat")(item.contactThreadCartBox.totalGrossPriceWithDiscount))+" PLN ")])]}},{key:"cell(statusOfOrder)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"text-nowrap",attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.$tc(("offer.status." + (item.contactThreadCartBox.contactThreadCartOrder.status)), 2))+" ")])]}},{key:"cell(paymentMethod)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"text-nowrap",attrs:{"variant":_vm.paymentTypeColors[item.contactThreadCartBox.paymentType]}},[_vm._v(" "+_vm._s(_vm.$t(_vm.paymentTypes[item.contactThreadCartBox.paymentType]))+" ")])]}},{key:"cell(commissionType)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{staticClass:"text-nowrap",attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.$t(value))+" ")])]}},{key:"cell(grossCommissionAmount)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("priceFormat")(item.value))+" PLN ")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"btn-icon ml-25 p-25",attrs:{"variant":item.status === 'REALIZED' ? 'flat-success' : 'flat-warning',"size":"sm","title":_vm.$t(item.status === 'REALIZED' ? 'QualifiedCommission' : 'NotionalCommission')}},[_c('feather-icon',{attrs:{"icon":item.status === 'REALIZED' ? 'CheckCircleIcon' : 'AlertCircleIcon'}})],1)],1)]}},{key:"cell(toPaid)",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("priceFormat")(value))+" PLN ")])]}},{key:"cell(sellerDiscount)",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("priceFormat")(value))+" PLN ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(paid)",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("priceFormat")(value))+" PLN ")])]}},{key:"cell(paymentStatus)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.$t(("PaymentStatuses." + (item.contactThreadCartBox.contactThreadCartOrder.paymentStatus))))+" ")])]}},{key:"cell(orderId)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(item.contactThreadCartBox.contactThreadCartOrder.number)+" ")])]}},{key:"cell(orderDate)",fn:function(ref){
var item = ref.item;
return [(item.contactThreadCartBox && item.contactThreadCartBox.contactThreadCartOrder && item.contactThreadCartBox.contactThreadCartOrder.orderDate)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.contactThreadCartBox.contactThreadCartOrder.orderDate.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, _vm.$i18n.locale)))]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }