<template>
  <div
    class="d-flex flex-wrap align-items-center justify-content-between"
    style="gap: .34rem .5rem"
  >
    <b-badge
      v-if="infos.projectStatus"
      v-b-tooltip.hover.v-primary
      variant="light-primary"
      :title="$t('ProjectStatus')"
    >
      <template v-if="infos.projectStatus.name">
        {{ infos.projectStatus.name }}
      </template>

      <sw-icon
        v-else
        icon="MinusIcon"
      />
    </b-badge>

    <b-badge
      v-b-tooltip.hover.v-primary
      variant="light-primary"
      :title="$t('AllDocuments')"
      class="d-flex align-items-center mr-auto"
    >
      <feather-icon
        icon="FileIcon"
        class="mr-25"
      />

      {{ infos.documents || 0 }}
    </b-badge>

    <!--    <b-badge-->
    <!--      v-b-tooltip.hover.v-primary-->
    <!--      variant="light-primary"-->
    <!--      :title="$t(!isTask ? 'ProjectProgress' : 'microTaskProgress')"-->
    <!--      class="d-flex align-items-center mr-auto"-->
    <!--    >-->
    <!--      {{ infos.progress || 0 }}%-->
    <!--    </b-badge>-->

    <b-badge
      v-b-tooltip.hover.v-primary
      variant="light-primary"
      :title="$tc('microTasks', 2)"
      class="d-flex align-items-center mr-auto"
    >
      <feather-icon
        icon="SquareIcon"
        class="mr-25"
      />

      {{ infos.microTasks || 0 }}
    </b-badge>

    <b-badge
      v-b-tooltip.hover.v-danger
      variant="light-danger"
      :title="$t('MicroTasksThatAreDueInHours')"
      class="d-flex align-items-center mr-auto"
    >
      <feather-icon
        icon="XSquareIcon"
        class="mr-25"
      />
      {{ infos.endangeredTasks || 0 }}
    </b-badge>

    <b-badge
      v-b-tooltip.hover.v-primary
      variant="light-primary"
      :title="`${$t('Done1')} ${!isTask ? $t('tasks') : $tc('microTasks', 2)} / ${$t('All')}`"
      class="d-flex align-items-center mr-auto"
    >
      <feather-icon
        icon="CheckSquareIcon"
        class="mr-25"
      />

      {{ infos.completeTasks + ' / ' + (!isTask ? infos.tasks : infos.microTasks) }}
    </b-badge>

    <b-badge
      v-if="!isTask"
      v-b-tooltip.hover.v-danger
      variant="light-danger"
      :title="$t(!isTask ? 'TasksThatAreDueInHours' : 'MicroTasksThatAreDueInHours')"
      class="d-flex align-items-center mr-auto"
    >
      <feather-icon
        icon="AlertTriangleIcon"
        class="mr-25"
      />
      {{ infos.endangered || 0 }}
    </b-badge>

    <avatar-group
      :items="infos.assignedUsers"
      size="20"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    infos: {
      type: Object,
      required: true,
    },
    isTask: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
}
</script>
